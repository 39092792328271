import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: `0 auto ${theme.spacing(1)}`,
    maxWidth: 1200,
    paddingRight: theme.spacing(2),
  },
  sortButton: {
    textTransform: 'none',
    color: theme.palette.grey[600],
  },
}));
