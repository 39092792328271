import { ArrowDropDown, History } from '@mui/icons-material';
import { Toolbar, Tooltip, Typography, useMediaQuery } from '@mui/material';
import {
  EditAppBar,
  HistoryDialog,
  LoadingButton,
  useDialog,
  useMenu,
} from '@prismamedia/one-components';
import { FC, ReactNode, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GetRecipe } from '../../../__generated__/queries-recipe';
import { ActionsMenu } from '../../../components/ActionsMenu';
import { GlobalCharCounter } from '../../../components/GlobalCharCounter';
import { NEW_RECIPE_ID } from '../../../const/recipe';
import { paths } from '../../../routing';
import { theme } from '../../../theme';
import { formatDate } from '../../../utils/formatDate';
import {
  RecipeActionId,
  getAction,
  getStatus,
} from '../../../utils/recipeStatus';
import { useStyles } from './styles';

interface DetailsAppBarProps {
  recipe?: GetRecipe.Recipe;
  recipeHasChanged: boolean;
  requiredMessage?: ReactNode;
  saveRecipe: () => void;
  saveLoading: boolean;
  unlock: () => void;
}

export const DetailsAppBar: FC<DetailsAppBarProps> = ({
  recipe,
  recipeHasChanged,
  requiredMessage,
  saveRecipe,
  saveLoading,
  unlock,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { openDialog } = useDialog();
  const hasActions = !!getStatus(recipe?.status)?.actions.length;
  const [actionsLoading, setActionLoading] = useState(false);
  const seeFrontAction = getAction(RecipeActionId.SEE_FRONT);
  const { openMenu } = useMenu();

  const renderActionsMenuButton = () =>
    !!recipe && (
      <LoadingButton
        variant="outlined"
        data-testid="action-button"
        color="secondary"
        loading={actionsLoading}
        endIcon={hasActions && <ArrowDropDown />}
        onClick={(e) => {
          hasActions &&
            openMenu(
              e.currentTarget,
              <ActionsMenu recipe={recipe} setLoading={setActionLoading} />,
            );
        }}
        disabled={recipe?.id === NEW_RECIPE_ID || recipeHasChanged}
        classes={{ disabled: classes.statusButtonDisabled }}
      >
        {getStatus(recipe?.status)?.textSingular || '-'}
      </LoadingButton>
    );

  const actions = [
    ...(seeFrontAction &&
    getStatus(recipe?.status)?.actions.includes(RecipeActionId.SEE_FRONT)
      ? [
          {
            id: seeFrontAction.id,
            label: seeFrontAction.title || '',
            icon: seeFrontAction.icon,
            onClick: () => seeFrontAction.callBack?.({ recipe }),
          },
        ]
      : []),
    ...(recipe?.recipeHistories?.length
      ? [
          {
            id: 'history',
            label: 'Historique',
            icon: <History />,
            onClick: () => {
              openDialog(
                // TODO remove as any
                <HistoryDialog histories={recipe?.recipeHistories as any} />,
                {
                  maxWidth: 'lg',
                },
              );
            },
          },
        ]
      : []),
  ];

  return (
    <EditAppBar
      title={recipe && !recipe.title ? 'Nouvelle recette' : recipe?.title}
      subTitle={
        recipe?.publishedAt || recipe?.editedAt
          ? `${
              recipe?.status === 'Published'
                ? `Publié le ${formatDate(recipe?.publishedAt)} - `
                : ''
            }Dernière modification le ${formatDate(recipe?.editedAt)}`
          : undefined
      }
      onNavigateBack={() => {
        history.push(paths.HOME);
        unlock();
      }}
      actions={actions}
      save={{
        requiredFields: requiredMessage,
        loading: saveLoading,
        disabled: !recipeHasChanged || !!requiredMessage,
        onClick: saveRecipe,
      }}
      additionalElement={
        recipeHasChanged ? (
          <Tooltip title="La recette n'est pas sauvegardée">
            <div>{renderActionsMenuButton()}</div>
          </Tooltip>
        ) : (
          renderActionsMenuButton()
        )
      }
      concurrentialAccess={{ lockStatus: recipe, disableLockForV0: true }}
      toolbar={
        recipe &&
        (!smallScreen || requiredMessage) && (
          <Toolbar variant="dense" className={classes.toolbar}>
            {!smallScreen && (
              <div className={classes.wordCountMessage}>
                <Typography>Total de l'article :</Typography>
                <GlobalCharCounter
                  values={[
                    { content: recipe.title },
                    { content: recipe.instructions, type: 'Draft' },
                    { content: recipe.advice, type: 'Draft' },
                  ]}
                  className={classes.globalCharCounter}
                />
              </div>
            )}

            <Typography className={classes.requiredMessage} color="error">
              {requiredMessage}
            </Typography>
          </Toolbar>
        )
      }
    />
  );
};
