import React, { FC, useState, useEffect } from 'react';
import { useStyles } from './styles';
import {
  Typography,
  Divider,
  Tabs,
  Tab,
  CircularProgress,
  InputProps,
} from '@mui/material';
import clsx from 'clsx';
import {
  draftActions,
  MaterialDraft,
  parseDraftContent,
  useDebounce,
} from '@prismamedia/one-components';
import { getParsedIngredients, IngredientData } from './utils';
import { IngredientsTooltip } from './IngredientsTooltip';
import { ParsedIngredients } from './ParsedIngredients';

interface IngredientsProps {
  className?: string;
  ingredients?: string | null;
  setIngredients?: (ingredients: string) => void;
  setParsedIngredients?: (ingredients: string) => void;
}

export const Ingredients: FC<IngredientsProps> = ({
  className,
  ingredients,
  setIngredients,
  setParsedIngredients,
}) => {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = useState(0);
  const [ingredientData, setIngredientData] = useState<
    IngredientData | undefined
  >(undefined);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const debouncedValue = parseDraftContent(useDebounce(ingredients, 500));

  useEffect(() => {
    if (debouncedValue) {
      setLoading(true);
      getParsedIngredients(debouncedValue)
        .then((data) => {
          setError('');
          setIngredientData(data);
          setParsedIngredients && setParsedIngredients(JSON.stringify(data));
        })
        .catch((e) => {
          setError(e.message);
          setIngredientData(undefined);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setError('');
      setIngredientData(undefined);
      setParsedIngredients && setParsedIngredients('');
    }
  }, [debouncedValue, setParsedIngredients]);

  return (
    <div className={clsx(className, classes.wrapper)}>
      <div className={classes.leftSide}>
        <MaterialDraft
          inputProps={
            {
              'data-testid': 'detail-ingredients',
            } as InputProps
          }
          required
          label="Ingrédients"
          value={ingredients}
          onChange={(value) => {
            setIngredients && setIngredients(value);
          }}
          actions={[draftActions.BOLD, draftActions.ITALIC]}
        />
        {!!ingredientData?.split_ingredients?.length && (
          <Typography className={classes.ingredientsCount} variant="caption">
            {ingredientData.split_ingredients.reduce(
              (prev, section) => prev + (section?.ingredients?.length || 0),
              0,
            )}{' '}
            ingrédients
          </Typography>
        )}
        <IngredientsTooltip
          className={clsx(
            classes.tooltip,
            ingredients?.length && classes.tooltipShifted,
          )}
        />
        {loading && <CircularProgress size={20} className={classes.loader} />}
      </div>
      <Divider className={classes.divider} orientation="vertical" flexItem />
      <div className={classes.rightSide}>
        <Tabs
          value={tabIndex}
          onChange={(_, value) => setTabIndex(value)}
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab label="Automatique" className={classes.tab} />
          <Tab label="Référence" className={classes.tab} />
        </Tabs>
        <div className={classes.tabPanel}>
          <ParsedIngredients
            error={error}
            tabIndex={tabIndex}
            ingredientData={ingredientData}
          />
        </div>
      </div>
    </div>
  );
};
