import { GetCategory } from '../__generated__/queries-recipe';

const NEW_CATEGORY_ID = 'NEW_CATEGORY_ID';

export const getEmptyCategory = (brandKey: string): GetCategory.Category => ({
  __typename: 'Category',
  id: NEW_CATEGORY_ID,
  brandKey,
  title: '',
  medias: null,
  body: null,
  subtitle: null,
  slug: null,
  updatedAt: '',
  createdAt: '',
  status: null,
  parent: null,
  children: [],
  level: 0,
});
