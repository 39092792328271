import { gql } from '@apollo/client';
import { client } from '..';
import {
  GetUsersQuery,
  GetUsersQueryVariables,
} from '../../__generated__/queries-auth';

export const GET_USERS = gql`
  query GetUsers($where: UserWhereInput!) {
    users(first: 1000, where: $where) {
      id
      name
      email
      roles
    }
  }
`;

export const getUsers = async (name_contains: string) => {
  const { data, errors } = await client.query<
    GetUsersQuery,
    GetUsersQueryVariables
  >({
    query: GET_USERS,
    variables: { where: { name_contains } },
  });

  if (errors?.length) {
    throw errors[0];
  }
  return data?.users;
};
