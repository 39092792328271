import { LoginPage, UserDialog } from '@prismamedia/one-components';
import React, { FC, ComponentType } from 'react';
import {
  Switch,
  Route,
  RouteProps,
  RouteComponentProps,
  Redirect,
} from 'react-router-dom';
import { getUsers } from './apollo/queries/users.auth.graphql';
import { CategoryEditPage } from './pages/CategoryEditPage';
import { CategoryListPage } from './pages/CategoryListPage';
import { RecipeDetailsPage } from './pages/RecipeDetailsPage';
import { RecipeListPage } from './pages/RecipeListPage';
import { UnlockRecipePage } from './pages/UnlockRecipePage';
import { auth, isAdmin } from './utils/auth';
import { useLocationChange } from './utils/useLocationChange';
// TODO remove this import
import { RawUser } from '@prismamedia/one-user';

export const paths = {
  LOGIN: '/login',
  HOME: '/',
  DETAILS: '/:brandKey/edit/:id',
  RECIPE_UNLOCK: '/unlock/:id',
  NEW: '/:brandKey/new',
  CATEGORY_LIST: '/category/list',
  CATEGORY_EDIT: '/:brandKey/category/edit/:id',
  CATEGORY_NEW: '/:brandKey/category/new',
};

export const replaceParams = (path: string, params: Record<string, string>) =>
  Object.entries(params)
    .reduce((prev, [key, value]) => prev.replace(`:${key}`, value), path)
    .replace(/\(.*\)/, '');

interface PrivateRouteProps extends RouteProps {
  component: ComponentType<RouteComponentProps>;
}

const PrivateRoute = ({ component: Component, ...rest }: PrivateRouteProps) => (
  <Route
    {...rest}
    render={(props) =>
      auth.isAuthenticated() ? (
        <>
          <Component {...props} />
          {/* TODO update one-component and remove force the type of getUsers: as (query: string) => Promise<RawUser[]> */}
          <UserDialog
            auth={auth}
            searchUsers={getUsers as (query: string) => Promise<RawUser[]>}
          />
        </>
      ) : (
        <Redirect to={{ pathname: paths.LOGIN }} />
      )
    }
  />
);

const AdminRoute = ({ component: Component, ...rest }: PrivateRouteProps) => (
  <Route
    {...rest}
    render={(props) =>
      auth.isAuthenticated() && isAdmin() ? (
        <>
          <Component {...props} />
          {/* TODO update one-component and remove force the type of getUsers: as (query: string) => Promise<RawUser[]> */}
          <UserDialog
            auth={auth}
            searchUsers={getUsers as (query: string) => Promise<RawUser[]>}
          />
        </>
      ) : (
        <Redirect to={{ pathname: paths.HOME }} />
      )
    }
  />
);

export const Routing: FC = () => {
  useLocationChange();

  return (
    <Switch>
      <Route
        exact
        path={paths.LOGIN}
        render={(props) => (
          <LoginPage
            {...props}
            homePath={paths.HOME}
            loginPath={paths.LOGIN}
            auth={auth}
          />
        )}
      />
      <PrivateRoute exact path={paths.HOME} component={RecipeListPage} />
      <PrivateRoute exact path={paths.DETAILS} component={RecipeDetailsPage} />
      <PrivateRoute
        exact
        path={paths.RECIPE_UNLOCK}
        component={UnlockRecipePage}
      />
      <PrivateRoute exact path={paths.NEW} component={RecipeDetailsPage} />
      <AdminRoute
        exact
        path={paths.CATEGORY_LIST}
        component={CategoryListPage}
      />
      <AdminRoute
        exact
        path={paths.CATEGORY_EDIT}
        component={CategoryEditPage}
      />
      <AdminRoute
        exact
        path={paths.CATEGORY_NEW}
        component={CategoryEditPage}
      />
      <PrivateRoute component={() => <Redirect to={paths.HOME} />} />
    </Switch>
  );
};
