import { ChipsInput, isDefined } from '@prismamedia/one-components';
import React, { FC } from 'react';
import { useFetchCategories } from '../../utils/useFetchCategories';
import { Recipe } from '../../__generated__/queries-recipe';

interface SecondaryCategoriesSelectProps {
  label: string;
  categories?: Recipe.RecipeCategories[];
  onChange?: (value: Recipe.Category[]) => void;
  className?: string;
  brandKey: string | null;
}

export const SecondaryCategoriesSelect: FC<SecondaryCategoriesSelectProps> = ({
  label,
  categories,
  onChange,
  className,
  brandKey,
}) => {
  const fetchCategories = useFetchCategories(
    brandKey,
    categories
      ?.filter(({ position }) => position === 1)
      ?.map(({ category }) => category?.id || ''),
  );

  const secondaryCategories = categories?.filter(
    ({ position }) => position > 1,
  );

  const notEmptyCategories = secondaryCategories
    ?.map(({ category }) => category)
    .filter(isDefined);

  return (
    <ChipsInput
      label={label}
      className={className}
      value={notEmptyCategories?.map((category) => ({
        id: category.id,
        label: category.title,
        additionnals: { category },
      }))}
      onChange={(items) => {
        onChange?.(items.map(({ additionnals }) => additionnals?.category));
      }}
      autocomplete={{
        fetchList: fetchCategories,
      }}
    />
  );
};
