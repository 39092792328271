import { SxProps } from '@mui/system';
import {
  MediaType,
  MultimediaManager2,
  NotificationTypeEnum,
  OneMedia,
  getImageUrl,
  handleDrop,
  isImage,
  useNotification,
} from '@prismamedia/one-components';
import { move } from 'ramda';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { BrandKey } from '../../__generated__/queries-web';
import { useFocusPointGetter } from '../../apollo/queries/focusPoint.image.graphql';
import { MediaFields } from './MediaFields';
import { MediaUploaders } from './MediaUploaders';
import { postImages } from './utils';

export const MediaInput: FC<{
  medias: OneMedia[];
  setMedias: Dispatch<SetStateAction<OneMedia[]>>;
  brandKey: BrandKey;
  sx?: SxProps;
  withMediaUploaders?: boolean;
}> = ({ medias, setMedias, brandKey, sx, withMediaUploaders }) => {
  const [selectedMediaIndex, setSelectedMediaIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const { pushNotification } = useNotification();
  const getFocusPoint = useFocusPointGetter();

  const selectedMedia = medias[selectedMediaIndex];
  const mediaHasError = (media: OneMedia) =>
    isImage(media) && (!media.credit || !media.source);

  const updateSelectedMedia: Dispatch<SetStateAction<OneMedia>> = (updater) => {
    setMedias((prev) =>
      prev.map((media, i) => {
        const newMedia =
          typeof updater === 'function' ? updater(media) : updater;
        return i === selectedMediaIndex ? newMedia : media;
      }),
    );
  };

  const fetchFocusPoint = async (media: OneMedia) => {
    const imageUrl = getImageUrl(media);
    const imageHeight = media.iframely.meta.height;
    const imageWidth = media.iframely.meta.width;
    try {
      const res = await getFocusPoint({
        imageUrl,
        imageHeight,
        imageWidth,
      });
      const { x, y, relativeX, relativeY } = res.getFocusPoint;
      return { x, y, relativeX, relativeY };
    } catch (e) {
      return undefined;
    }
  };

  const onUploadFiles = async (files: File[]) => {
    setLoading(true);
    try {
      const newMedias = await handleDrop({
        files,
        mediaType: MediaType.Image,
        disableImgWidth: true,
        handleFiles: postImages(brandKey),
        pushNotification,
      });

      const focusPoints = await Promise.all(
        newMedias.map((media) => fetchFocusPoint(media)),
      );

      const newMediasWithFocusPoint = newMedias.map((media, i) => ({
        ...media,
        focusPoint: focusPoints[i],
      }));

      const lastMediaIndex = medias.length - 1;
      setMedias((prev) => [...prev, ...newMediasWithFocusPoint]);
      setSelectedMediaIndex(lastMediaIndex + 1);
    } catch (error) {
      pushNotification({
        message: (error as Error).message || 'Média non reconnu',
        type: NotificationTypeEnum.error,
      });
    }
    setLoading(false);
  };

  return (
    <MultimediaManager2
      sx={sx}
      medias={medias}
      loading={loading}
      onMediaDelete={(index) =>
        setMedias((prev) => prev.filter((_, i) => i !== index))
      }
      onMediaReorder={(oldIndex, newIndex) =>
        setMedias((prev) => move(oldIndex, newIndex, prev))
      }
      onCropAndFocusPointChange={({ crop, focusPoint }, index) =>
        setMedias((prev) =>
          prev.map((media, i) =>
            i === index ? { ...media, focusPoint, crop } : media,
          ),
        )
      }
      mediaWithErrorIndexes={medias.reduce<number[]>(
        (prev, media, i) => (mediaHasError(media) ? [...prev, i] : prev),
        [],
      )}
      selectedMediaIndex={selectedMediaIndex}
      setSelectedMediaIndex={setSelectedMediaIndex}
      onUploadFiles={onUploadFiles}
      mediaUploaders={
        withMediaUploaders && (
          <MediaUploaders
            medias={medias}
            setMedias={setMedias}
            brandKey={brandKey}
            setSelectedMediaIndex={setSelectedMediaIndex}
          />
        )
      }
      mediaFields={
        <MediaFields media={selectedMedia} setMedia={updateSelectedMedia} />
      }
    />
  );
};
