import {
  DocumentNode,
  OperationVariables,
  QueryOptions,
  TypedDocumentNode,
  useApolloClient,
} from '@apollo/client';
import { useCallback } from 'react';

export function useQueryPromise<Query, Variables extends OperationVariables>(
  query: DocumentNode | TypedDocumentNode<Query, Variables>,
  options?: Omit<QueryOptions<Variables, Query>, 'query' | 'variables'>,
) {
  const client = useApolloClient();

  return useCallback(
    (variables: Variables) =>
      client
        .query<Query, Variables>({
          query,
          ...options,
          variables,
        })
        .then(({ data, errors }) => {
          if (errors?.length) {
            throw errors[0];
          }
          return data;
        }),
    [client, options, query],
  );
}
