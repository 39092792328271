import {
  MEDIA_TYPES_LABEL,
  MultimediaPlugin,
} from '@prismamedia/one-components';
import { FC, useState } from 'react';
import { BrandKey } from '../../../__generated__/queries-web';
import { allowedMedias } from '../MultimediaPlugin/MultimediaPluginButton';
import { searchByPluginType } from '../searchArticleForMedia';
import {
  DEFAULT_PAGE_RESULT,
  SEARCH_PAGE_RESULT,
  getItemToIframely,
  getItemToRender,
} from '../utils';

interface MultimediaPluginLinkProps {
  searchText?: string;
  brandKey: BrandKey;
  onLinkSelected: (link: any) => void;
  onClose: () => void;
}

export const MultimediaPluginLink: FC<MultimediaPluginLinkProps> = ({
  searchText,
  brandKey,
  onLinkSelected,
  onClose,
}) => {
  const [value, setValue] = useState<string>(searchText || '');

  const onSearch = async (search: string | undefined, pluginType: string) => {
    const searchTerm = value.length ? value : search;
    return searchByPluginType(
      searchTerm,
      pluginType || MEDIA_TYPES_LABEL.ARTICLES,
      brandKey,
      1,
      search?.length ? SEARCH_PAGE_RESULT : DEFAULT_PAGE_RESULT,
    );
  };

  return (
    <MultimediaPlugin
      allowedMedias={allowedMedias}
      defaultActions={false}
      defaultText={searchText}
      itemToIframely={async (item: any, pluginType?: string) =>
        getItemToIframely(item, pluginType || MEDIA_TYPES_LABEL.ARTICLES)
      }
      itemToRender={(item: any, pluginType?: string) =>
        getItemToRender(
          brandKey,
          item,
          pluginType || MEDIA_TYPES_LABEL.ARTICLES,
        )
      }
      onClick={async (item: any) => {
        onLinkSelected(item);
      }}
      onClose={onClose}
      onSave={onLinkSelected}
      searchFn={async (search, opts, pluginType) => {
        setValue('');
        return onSearch(search.search, pluginType);
      }}
    />
  );
};
