import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useStyles } from './styles';
import { TimeInput } from '../../../components/TimeInput';
import { useDialog, SimpleSelect } from '@prismamedia/one-components';
import { TagsDialog } from '../TagsDialog';
import { Tags } from './Tags';
import { GetRecipe, Recipe } from '../../../__generated__/queries-recipe';
import { CategorySelect } from '../../../components/CategorySelect';
import { AuthorSelect } from '../../../components/AuthorSelect';
import {
  difficulties,
  estimatedCosts,
  calorieLevels,
} from '../../../const/selectsValues';
import { NEW_RECIPE_CATEGORY_ID } from '../utils';
import { SecondaryCategoriesSelect } from '../../../components/SecondaryCategoriesSelect';

interface InformationsDrawerProps {
  recipe?: GetRecipe.Recipe;
  setRecipe: Dispatch<SetStateAction<GetRecipe.Recipe | undefined>>;
}

export const InformationsDrawer: FC<InformationsDrawerProps> = ({
  recipe,
  setRecipe,
}) => {
  const classes = useStyles();
  const { openDialog } = useDialog();

  const mainCategory = recipe?.recipeCategories.find(
    ({ position }) => position === 1,
  )?.category;

  const handleRecipeChange = useCallback(
    (key: keyof GetRecipe.Recipe) => (value: any) => {
      setRecipe((prev) =>
        prev
          ? {
              ...prev,
              [key]: value,
            }
          : prev,
      );
    },
    [setRecipe],
  );

  const handleMainCategoryChange = useCallback(
    (category: Recipe.Category | null) => {
      setRecipe((prev) => {
        if (!prev) return prev;
        const prevRecipeCategory = prev.recipeCategories.find(
          (c) => c.position === 1,
        );

        return {
          ...prev,
          recipeCategories: [
            ...prev.recipeCategories.filter((cat) => cat.position !== 1),
            {
              ...(prevRecipeCategory || {
                __typename: 'RecipeCategory',
                position: 1,
                id: NEW_RECIPE_CATEGORY_ID,
              }),
              category,
            },
          ],
        };
      });
    },
    [setRecipe],
  );

  const handleSecondaryCategoriesChange = useCallback(
    (categories: Recipe.Category[]) => {
      setRecipe((prev) => {
        if (!prev) return prev;

        const prevSecondaryCategories = prev.recipeCategories.filter(
          ({ position }) => position !== 1,
        );

        return {
          ...prev,
          recipeCategories: [
            ...prev.recipeCategories.filter((cat) => cat.position === 1),
            ...categories.map((category, index) => ({
              __typename: 'RecipeCategory' as const,
              position: index + 2,
              id: NEW_RECIPE_CATEGORY_ID,
              category,
            })),
            ...prevSecondaryCategories.map((recipeCategory) => ({
              ...recipeCategory,
              category: null,
            })),
          ],
        };
      });
    },
    [setRecipe],
  );

  if (!recipe) return null;

  return (
    <>
      <Button
        startIcon={<Add />}
        variant="contained"
        size="small"
        color="inherit"
        classes={{ root: classes.addTagButton }}
        data-testid="tag-button"
        onClick={() =>
          openDialog(
            <TagsDialog
              tags={recipe.recipeTags}
              setTags={(recipeTags) =>
                setRecipe((prev) => (prev ? { ...prev, recipeTags } : prev))
              }
            />,
            {
              fullScreen: true,
            },
          )
        }
      >
        Ajouter un tag
      </Button>
      <Tags
        recipeTags={recipe.recipeTags}
        setRecipeTags={(recipeTags) =>
          setRecipe((prev) => (prev ? { ...prev, recipeTags } : prev))
        }
      />
      <form className={classes.form} noValidate autoComplete="off">
        <AuthorSelect
          label="Auteur"
          authorId={recipe.author}
          onSelect={handleRecipeChange('author')}
          className={classes.input}
        />
        <CategorySelect
          label="Catégorie principale"
          category={mainCategory}
          onSelect={handleMainCategoryChange}
          className={classes.input}
          required
          brandKey={recipe.brandKey}
          selectedCategories={recipe.recipeCategories}
        />
        <SecondaryCategoriesSelect
          label="Catégories secondaires"
          categories={recipe.recipeCategories}
          onChange={handleSecondaryCategoriesChange}
          className={classes.input}
          brandKey={recipe.brandKey}
        />
        <SimpleSelect
          label="Difficulté"
          variant="standard"
          value={recipe.difficulty || ''}
          options={difficulties}
          className={classes.input}
          onChange={handleRecipeChange('difficulty')}
        />
        <SimpleSelect
          label="Niveau de prix"
          variant="standard"
          value={recipe.estimatedCost || ''}
          options={estimatedCosts}
          className={classes.input}
          onChange={handleRecipeChange('estimatedCost')}
        />
        <SimpleSelect
          label="Taux de calories"
          variant="standard"
          value={recipe.calorieLevel || ''}
          options={calorieLevels}
          className={classes.input}
          onChange={handleRecipeChange('calorieLevel')}
        />
        <TimeInput
          label="Temps de préparation"
          value={recipe.prepTime || 0}
          className={classes.input}
          onChange={handleRecipeChange('prepTime')}
        />
        <TimeInput
          label="Temps de cuisson"
          value={recipe.cookTime || 0}
          className={classes.input}
          onChange={handleRecipeChange('cookTime')}
        />
        <TimeInput
          label="Temps d'attente"
          value={recipe.waitTime || 0}
          className={classes.input}
          onChange={handleRecipeChange('waitTime')}
        />
      </form>
    </>
  );
};
