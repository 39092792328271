import { TextField } from '@mui/material';
import { OneMedia, isImage } from '@prismamedia/one-components';
import { Dispatch, FC, SetStateAction } from 'react';
import { ImageSourceSelect } from '../ImageSourceSelect';

export const MediaFields: FC<{
  media: OneMedia;
  setMedia: Dispatch<SetStateAction<OneMedia>>;
}> = ({ media, setMedia }) => {
  if (!media) return null;

  return (
    <>
      {isImage(media) && (
        <TextField
          label="Crédit"
          required
          sx={{ mb: 3 }}
          variant="standard"
          value={media.credit || ''}
          onChange={({ target }) =>
            setMedia((prev) => ({ ...prev, credit: target.value }))
          }
          error={!media.credit}
          helperText={!media.credit && 'Le champ "credit" du média est requis'}
        />
      )}
      <TextField
        label="Légende"
        sx={{ mb: 3 }}
        variant="standard"
        value={media.caption || ''}
        onChange={({ target }) =>
          setMedia((prev) => ({ ...prev, caption: target.value }))
        }
      />
      {isImage(media) && (
        <ImageSourceSelect
          value={
            media.source
              ? {
                  id: media.source,
                  label: media.source,
                }
              : undefined
          }
          label="Source"
          onChange={(value) =>
            setMedia((prev) => ({
              ...prev,
              source: value ? (value.label as string) : '',
            }))
          }
          TextFieldProps={{
            required: true,
            error: !media.source,
            helperText:
              !media.source && 'Le champ "source" du média est requis',
          }}
        />
      )}
    </>
  );
};
