import { useMutation, gql, useApolloClient } from '@apollo/client';
import {
  RecipeUpdateInput,
  RecipeCreateInput,
  UpdateRecipeMutation,
  UpdateRecipeMutationVariables,
  CreateRecipeMutation,
  CreateRecipeMutationVariables,
} from '../../__generated__/queries-recipe';
import { GET_RECIPES } from '../queries/recipes.recipe.graphql';
import {
  useNotification,
  NotificationTypeEnum,
} from '@prismamedia/one-components';
import { RECIPE_FRAGMENT } from '../fragments/recipe.recipe.graphql';
import { useRecipeVariables } from '../../pages/RecipeListPage/utils';
import { useCallback } from 'react';

const UPDATE_RECIPE = gql`
  mutation UpdateRecipe(
    $where: RecipeWhereUniqueInput!
    $data: RecipeUpdateInput!
  ) {
    updateRecipe(where: $where, data: $data) {
      ...Recipe
    }
  }
  ${RECIPE_FRAGMENT}
`;

export const useUpdateRecipe = () => {
  const { pushNotification } = useNotification();
  const variables = useRecipeVariables();
  const client = useApolloClient();

  const [updateRecipe] = useMutation<
    UpdateRecipeMutation,
    UpdateRecipeMutationVariables
  >(UPDATE_RECIPE, {
    awaitRefetchQueries: true,
    onError: (e) => {
      pushNotification({
        message: e.message,
        type: NotificationTypeEnum.error,
      });
    },
  });

  return useCallback(
    (recipeId: string, data: RecipeUpdateInput) =>
      updateRecipe({
        variables: {
          where: {
            id: recipeId,
          },
          data,
        },
        refetchQueries: (res) => {
          if (data.status === undefined) return [];

          const recipe = client.readFragment({
            id: `Recipe:${recipeId}`,
            fragment: RECIPE_FRAGMENT,
          });

          return [recipe.status, res.data?.updateRecipe?.status].map(
            (status) => ({
              query: GET_RECIPES,
              variables: {
                ...variables,
                where: {
                  ...variables.where,
                  status,
                },
              },
            }),
          );
        },
      }),
    [client, updateRecipe, variables],
  );
};

export const useUpdateRecipeLockerId = () => {
  const [updateRecipe] = useMutation<
    UpdateRecipeMutation,
    UpdateRecipeMutationVariables
  >(UPDATE_RECIPE);

  return useCallback(
    (id: string, lockerId: string | null, noCacheUpdate?: boolean) =>
      updateRecipe({
        variables: {
          where: { id },
          data: { lockerId },
        },
        fetchPolicy: noCacheUpdate ? 'no-cache' : undefined,
      }),
    [updateRecipe],
  );
};

const CREATE_RECIPE = gql`
  mutation CreateRecipe($data: RecipeCreateInput!) {
    createRecipe(data: $data) {
      ...Recipe
    }
  }
  ${RECIPE_FRAGMENT}
`;

export const useCreateRecipe = () => {
  const { pushNotification } = useNotification();
  const variables = useRecipeVariables();
  const [createRecipe] = useMutation<
    CreateRecipeMutation,
    CreateRecipeMutationVariables
  >(CREATE_RECIPE, {
    refetchQueries: (res) => [
      {
        query: GET_RECIPES,
        variables: {
          ...variables,
          where: {
            ...variables.where,
            status: res.data?.createRecipe?.status,
          },
        },
      },
    ],
    onError: (e) => {
      pushNotification({
        message: e.message,
        type: NotificationTypeEnum.error,
      });
    },
  });

  return useCallback(
    (data: RecipeCreateInput) =>
      createRecipe({
        variables: {
          data,
        },
      }),
    [createRecipe],
  );
};
