import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  centered: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
  },
}));
