import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
  },
  leftSide: {
    flexBasis: `calc(50% - ${theme.spacing(2)} - 1px)`,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(2),
    position: 'relative',
  },
  tooltip: {
    position: 'absolute',
    top: 32,
    right: -5,
    transition: 'top 300ms',
    zIndex: 1,
  },
  tooltipShifted: {
    top: 10,
  },
  loader: {
    position: 'absolute',
    top: 15,
    right: 30,
  },
  rightSide: {
    flexBasis: `calc(50% - ${theme.spacing(2)})`,
    width: `calc(50% - ${theme.spacing(2)})`,
    flexShrink: 0,
  },
  divider: {
    margin: `0 ${theme.spacing(2)}`,
  },
  ingredientsCount: {
    alignSelf: 'flex-end',
    marginTop: theme.spacing(1),
    color: theme.palette.grey[600],
  },
  tab: {
    minWidth: '50%',
    flexShrink: 1,
  },
  tabPanel: {
    padding: `${theme.spacing(1)} 0`,
  },
  label: {
    color: 'gray',
  },
}));
