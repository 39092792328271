import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  title: {
    flex: 1,
  },
  wrapper: {
    maxWidth: 'calc(100vw - 48px)',
    width: 1000,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
  },
  textFieldWrapper: {
    position: 'relative',
  },
  textField: {
    width: '100%',
  },
  resetButton: {
    position: 'absolute',
    bottom: 2,
    right: 0,
  },
  list: {
    flex: 1,
  },
  tabsWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(4),
  },
  tabsTitle: {
    textTransform: 'uppercase',
    marginRight: 'auto',
    color: theme.palette.grey[600],
  },
}));
