import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: `${theme.spacing(2)} -${theme.spacing(1)} ${theme.spacing(3)} 0`,
  },
  tag: {
    margin: `0 ${theme.spacing(1)} ${theme.spacing(1)} 0`,
  },
}));
