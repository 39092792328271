import { GetRecipe } from '../__generated__/queries-recipe';

export const NEW_RECIPE_ID = 'NEW_RECIPE_ID';

export const getEmptyRecipe = (brandKey: string): GetRecipe.Recipe => ({
  __typename: 'Recipe',
  id: NEW_RECIPE_ID,
  brandKey,
  title: '',
  publishedAt: null,
  createdAt: '',
  editedAt: null,
  author: null,
  authorName: null,
  status: 'Draft',
  medias: null,
  cookTime: null,
  prepTime: null,
  waitTime: null,
  instructions: '',
  advice: '',
  difficulty: null,
  rawIngredients: '',
  parsedIngredients: '',
  calorieLevel: null,
  estimatedCost: null,
  yield: null,
  recipeCategories: [],
  recipeTags: [],
  recipeUrls: [],
  recipeHistories: [],
  lockedAt: null,
  lockedBy: null,
});
