import { BurstMode, Code, Videocam } from '@mui/icons-material';
import {
  EmbedDialog,
  IconLabelButton,
  MEDIA_TYPES_LABEL,
  MultimediaPlugin,
  OneMedia,
  useDialog,
} from '@prismamedia/one-components';
import { Dispatch, FC, SetStateAction } from 'react';
import { BrandKey } from '../../__generated__/queries-web';
import { searchByPluginType } from './searchArticleForMedia';
import {
  DEFAULT_PAGE_RESULT,
  SEARCH_PAGE_RESULT,
  embedImage,
  getItemToIframely,
  getItemToRender,
} from './utils';

export const MediaUploaders: FC<{
  medias: OneMedia[];
  setMedias: Dispatch<SetStateAction<OneMedia[]>>;
  brandKey: BrandKey;
  setSelectedMediaIndex: (index: number) => void;
}> = ({ medias, setMedias, brandKey, setSelectedMediaIndex }) => {
  const { openDialog, closeDialog } = useDialog();

  const openEmbedDialog = () =>
    openDialog(
      <EmbedDialog
        fetcher={embedImage}
        onMediaLoaded={(media) => {
          const lastMediaIndex = medias.length - 1;
          setMedias((prev) => [...prev, media]);
          setSelectedMediaIndex(lastMediaIndex + 1);
          closeDialog();
        }}
      />,
      { fullWidth: true },
    );

  return (
    <>
      <IconLabelButton label="Lien" onClick={openEmbedDialog}>
        <Code />
      </IconLabelButton>

      <MultimediaPlugin
        addMedia={(mediaOrMedias) => {
          const newMedias = Array.isArray(mediaOrMedias)
            ? mediaOrMedias
            : [mediaOrMedias];
          const lastMediaIndex = medias.length - 1;
          setMedias((prev) => [...prev, ...newMedias]);
          setSelectedMediaIndex(lastMediaIndex + 1);
        }}
        allowedMedias={[
          {
            Icon: BurstMode,
            label: 'Diapo',
            type: MEDIA_TYPES_LABEL.SLIDESHOWS,
          },
          {
            Icon: Videocam,
            label: 'Video',
            type: MEDIA_TYPES_LABEL.VIDEOS,
          },
        ]}
        searchFn={(searchParams, opts, pluginType) =>
          searchByPluginType(
            searchParams.search,
            pluginType,
            brandKey,
            1,
            searchParams.length ? SEARCH_PAGE_RESULT : DEFAULT_PAGE_RESULT,
          )
        }
        itemToIframely={async (item, pluginType) =>
          getItemToIframely(item, pluginType)
        }
        itemToRender={(item, pluginType) =>
          getItemToRender(brandKey, item, pluginType)
        }
      />
    </>
  );
};
