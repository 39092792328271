import {
  Collections,
  Description,
  Restaurant,
  Videocam,
} from '@mui/icons-material';
import {
  ItemSerialized,
  MEDIA_TYPES_LABEL,
  MediaFormat,
  MultimediaPlugin,
  PluginButtonProps,
  getFirstMedia,
  mediaFromIframely,
  parseMedias,
} from '@prismamedia/one-components';
import { EditorState } from 'draft-js';
import { insertDataBlock } from 'megadraft';
import { FC, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { searchByPluginType } from '../searchArticleForMedia';
import {
  DEFAULT_PAGE_RESULT,
  SEARCH_PAGE_RESULT,
  baseRecipesURLs,
  embedImage,
  getArticleUrl,
  getItemToIframely,
  getItemToRender,
} from '../utils';
import { buildDraftLink } from './utils';

export const allowedMedias = [
  {
    Icon: Description,
    label: 'Article',
    type: MEDIA_TYPES_LABEL.ARTICLES,
  },
  {
    Icon: Restaurant,
    label: 'Recette',
    type: MEDIA_TYPES_LABEL.RECIPES,
  },
  {
    Icon: Videocam,
    label: 'Video',
    type: MEDIA_TYPES_LABEL.VIDEOS,
  },
  {
    Icon: Collections,
    label: 'Diapo',
    type: MEDIA_TYPES_LABEL.SLIDESHOWS,
  },
];

export const MultimediaPluginButton: FC<PluginButtonProps> = ({
  editorState,
  onChange,
}) => {
  const { brandKey } = useParams<{ brandKey: any }>();

  const onSearch = useCallback(
    async (search: string | undefined, pluginType: string) =>
      await searchByPluginType(
        search,
        pluginType,
        brandKey,
        1,
        search?.length ? SEARCH_PAGE_RESULT : DEFAULT_PAGE_RESULT,
      ),
    [brandKey],
  );

  const onSave = useCallback(
    (items: ItemSerialized[]) => {
      let newEditorState = editorState;

      items.map(async ({ pluginType, ...item }) => {
        switch (pluginType) {
          case MEDIA_TYPES_LABEL.RECIPES: {
            const url =
              baseRecipesURLs[brandKey] + item.recipeUrls[0].url?.path || '';
            const newBlock = { id: item.id, title: item.title, url };

            newEditorState = buildDraftLink(
              newEditorState,
              newBlock,
            ) as EditorState;
            break;
          }

          case MEDIA_TYPES_LABEL.VIDEOS: {
            const media = getFirstMedia(
              parseMedias(item.medias),
              MediaFormat.Video,
            );

            if (media) {
              const iframely = await embedImage(media.iframely.meta.canonical);
              const newBlock = {
                type: 'media',
                ...mediaFromIframely(iframely),
              };

              newEditorState = insertDataBlock(newEditorState, newBlock);
            }
            break;
          }

          default: {
            const url = getArticleUrl(item?.articleUrls);
            const newBlock = { id: item.id, title: item.title, url };

            newEditorState = buildDraftLink(
              newEditorState,
              newBlock,
            ) as EditorState;
            break;
          }
        }

        onChange(newEditorState);
      });
    },
    [brandKey, editorState, onChange],
  );

  return (
    <MultimediaPlugin
      allowedMedias={allowedMedias}
      customStyles={{ display: 'flex' }}
      itemToIframely={async (item, pluginType) =>
        await getItemToIframely(item, pluginType)
      }
      itemToRender={(item, pluginType) =>
        getItemToRender(brandKey, item, pluginType)
      }
      onSave={onSave}
      searchFn={async (searchParams, _, pluginType) =>
        onSearch(searchParams.search, pluginType)
      }
    />
  );
};
