import { formatISO } from 'date-fns';
import {
  GetRecipe,
  Recipe,
  RecipeCreateInput,
  RecipeUpdateInput,
} from '../../__generated__/queries-recipe';
import { paths, replaceParams } from '../../routing';

export const NEW_RECIPE_CATEGORY_ID = 'NEW_RECIPE_CATEGORY_ID';
export const NEW_RECIPE_TAG_ID = 'NEW_RECIPE_TAG_ID';
export const NEW_TAG_ID = 'NEW_TAG_ID';

interface RecipeCategoryDefined extends Recipe.RecipeCategories {
  category: Recipe.Category;
}

interface RecipeTagDefined extends Recipe.RecipeTags {
  tag: Recipe.Tag;
}

export const getRecipeUpdateInput = (
  recipe: GetRecipe.Recipe,
): RecipeUpdateInput => {
  const unmutableKeys: (keyof GetRecipe.Recipe)[] = [
    'id',
    '__typename',
    'brandKey',
    'status',
    'lockedBy',
    'lockedAt',
    'createdAt',
    'authorName',
    'editedAt',
  ];

  const recipeClone = { ...recipe };
  unmutableKeys.forEach((key) => {
    delete recipeClone[key];
  });

  const recipeCategoriesToCreate = recipe.recipeCategories.filter(
    ({ id, category }) => id === NEW_RECIPE_CATEGORY_ID && category,
  ) as RecipeCategoryDefined[];
  const recipeCategoriesToDelete = recipe.recipeCategories.filter(
    ({ category }) => !category,
  );
  const recipeCategoriesToUpdate = recipe.recipeCategories.filter(
    ({ id, category }) => id !== NEW_RECIPE_CATEGORY_ID && category,
  ) as RecipeCategoryDefined[];

  const recipeTagsToCreate = recipe.recipeTags.filter(
    ({ id, tag }) => id.startsWith(NEW_RECIPE_TAG_ID) && tag,
  ) as RecipeTagDefined[];
  const recipeTagsToDelete = recipe.recipeTags.filter(
    ({ id, tag }) => !id.startsWith(NEW_RECIPE_TAG_ID) && !tag,
  );

  return {
    ...recipeClone,
    recipeCategories: {
      create: recipeCategoriesToCreate.map(({ position, category }) => ({
        position,
        category: { connect: { id: category.id } },
      })),
      delete: recipeCategoriesToDelete.map(({ id }) => ({ id })),
      update: recipeCategoriesToUpdate.map(({ id, category }) => ({
        where: { id },
        data: {
          category: { connect: { id: category.id } },
        },
      })),
    },
    recipeTags: {
      create: recipeTagsToCreate.map(({ tag }) => ({
        tag:
          tag.id === NEW_TAG_ID
            ? { create: { title: tag.title } }
            : { connect: { id: tag.id } },
      })),
      delete: recipeTagsToDelete.map(({ id }) => ({ id })),
    },
    editedAt: formatISO(new Date()),
    recipeUrls: undefined,
    recipeHistories: undefined,
  };
};

export const getRecipeCreateInput = (
  recipe: GetRecipe.Recipe,
): RecipeCreateInput => {
  const unmutableKeys: (keyof GetRecipe.Recipe)[] = [
    'id',
    '__typename',
    'lockedBy',
    'lockedAt',
    'createdAt',
    'authorName',
    'editedAt',
  ];

  const recipeClone = { ...recipe };
  unmutableKeys.forEach((key) => {
    delete recipeClone[key];
  });

  const recipeCategoriesToCreate = recipe.recipeCategories.filter(
    ({ id, category }) => id === NEW_RECIPE_CATEGORY_ID && category,
  ) as RecipeCategoryDefined[];

  const recipeTagsToCreate = recipe.recipeTags.filter(
    ({ id, tag }) => id.startsWith(NEW_RECIPE_TAG_ID) && tag,
  ) as RecipeTagDefined[];

  return {
    ...recipeClone,
    recipeCategories: {
      create: recipeCategoriesToCreate.map(({ position, category }) => ({
        position,
        category: { connect: { id: category.id } },
      })),
    },
    recipeTags: {
      create: recipeTagsToCreate.map(({ tag }) => ({
        tag:
          tag.id === NEW_TAG_ID
            ? { create: { title: tag.title } }
            : { connect: { id: tag.id } },
      })),
    },
    editedAt: formatISO(new Date()),
    recipeUrls: undefined,
    recipeHistories: undefined,
  };
};

export const getUnlockLink = (id: string) =>
  `${window.location.origin}${replaceParams(paths.RECIPE_UNLOCK, {
    id,
  })}`;
