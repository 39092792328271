import { gql, useQuery } from '@apollo/client';
import {
  GetBrandKeysQuery,
  GetBrandKeysQueryVariables,
} from '../../__generated__/queries-web';

export const GET_BRANDKEYS = gql`
  query GetBrandKeys($first: Int!, $where: CategoryWhereInput!) {
    categories(where: $where, first: $first) {
      id
      brandKey
    }
  }
`;

export const useGetBrandKeys = (skip?: boolean) =>
  useQuery<GetBrandKeysQuery, GetBrandKeysQueryVariables>(GET_BRANDKEYS, {
    variables: {
      first: 100,
      where: {
        level: 2,
        format: 'Category',
        brandKey_in: ['CAC', 'FAC'],
      },
    },
    skip,
  });
