import { SortDirection } from '@mui/material';
import { RecipeOrderByInput } from '../__generated__/queries-recipe';

enum SortMethodId {
  MODIFICATION_DATE = 'MODIFICATION_DATE',
  CREATION_DATE = 'CREATION_DATE',
}

interface SortMethod {
  id: SortMethodId;
  title: string;
}

export interface Sort {
  direction: SortDirection;
  method: SortMethod;
}

export const sortMethods: SortMethod[] = [
  {
    id: SortMethodId.CREATION_DATE,
    title: 'Date de création',
  },
  {
    id: SortMethodId.MODIFICATION_DATE,
    title: 'Date de modification',
  },
];

export const getOrderBy = (sort: Sort): RecipeOrderByInput => {
  if (sort.method.id === SortMethodId.MODIFICATION_DATE) {
    if (sort.direction === 'desc') {
      return 'updatedAt_DESC';
    }
    return 'updatedAt_ASC';
  }
  if (sort.direction === 'desc') {
    return 'createdAt_DESC';
  }
  return 'createdAt_ASC';
};

export const extractFromOrderBy = (
  orderBy: RecipeOrderByInput,
): { method?: SortMethod; direction?: SortDirection } => {
  switch (orderBy) {
    case 'updatedAt_DESC':
      return { method: sortMethods[1], direction: 'desc' };
    case 'updatedAt_ASC':
      return { method: sortMethods[1], direction: 'asc' };
    case 'createdAt_DESC':
      return { method: sortMethods[0], direction: 'desc' };
    case 'createdAt_ASC':
      return { method: sortMethods[0], direction: 'asc' };
    default:
      return {};
  }
};
