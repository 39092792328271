import React, { FC, useState } from 'react';
import { Popover, Typography, IconButton } from '@mui/material';
import { Info } from '@mui/icons-material';
import { useStyles } from './styles';

interface IngredientsTooltipProps {
  className: string;
}

export const IngredientsTooltip: FC<IngredientsTooltipProps> = ({
  className,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
        className={className}
        size="small"
      >
        <Info />
      </IconButton>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={{ paper: classes.wrapper }}
      >
        <Typography variant="body1">
          Consignes pour la saisie des ingrédients
        </Typography>
        <Typography variant="body2" className={classes.body}>
          Utiliser des tournures telles que "<b>quantité unité ingrédients</b>"
          (ex : Le jus de citron / 1 citron pressé).
        </Typography>
        <Typography variant="body2" className={classes.body}>
          Si <b>copier/coller depuis word</b>, bien penser à faire{' '}
          <b>Cmd + Shift + V</b> (mac) ou <b>Ctrl + Shift + V</b> (windows) pour
          ne pas avoir d'erreur lors de la prévisualisation.
        </Typography>
        <Typography variant="body2" className={classes.body}>
          Si un ou des ingrédients sont "<b>non valide</b>", se référer aux{' '}
          <a
            href="https://docs.google.com/presentation/d/1oc6Dw6MFkRu-ddiA1cul5EBdcoBzf5fPYGhhvD-W6qo/edit#slide=id.g1ba9544cde_0_135"
            target="_blank"
            rel="noopener noreferrer"
          >
            bonnes pratiques
          </a>{' '}
          pour comprendre d'où vient l'erreur.
        </Typography>
      </Popover>
    </>
  );
};
