import { Typography } from '@mui/material';
import {
  EditAppBar,
  PageWithDrawer,
  useOnMount,
} from '@prismamedia/one-components';
import React, { FC, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useUpdateRecipeLockerId } from '../../apollo/mutations/recipes.recipe.graphql';
import { useGetRecipe } from '../../apollo/queries/recipes.recipe.graphql';
import { paths } from '../../routing';
import { useStyles } from './styles';

export const UnlockRecipePage: FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const updateLockerId = useUpdateRecipeLockerId();
  const { id: recipeId } = useParams<{ id: string }>();
  const { data } = useGetRecipe(recipeId);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | undefined>(undefined);

  useOnMount(() => {
    updateLockerId(recipeId, null)
      .catch((e) => {
        setError(e);
      })
      .finally(() => setLoading(false));
  });

  return (
    <>
      <EditAppBar
        onNavigateBack={() => history.push(paths.HOME)}
        title={data?.recipe?.title}
      />
      <PageWithDrawer loading={loading} error={error}>
        <Typography className={classes.centered} variant="h4" color="primary">
          La fiche a bien été déverrouillée, vous pouvez fermer cette page
        </Typography>
      </PageWithDrawer>
    </>
  );
};
