import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getOperationName } from '@apollo/client/utilities';
import { auth } from '../utils/auth';
import { SEARCH_ARTICLES } from './queries/articles.web.graphql';
import { GET_AUTHOR, GET_AUTHORS } from './queries/authors.web.graphql';
import { GET_BRANDKEYS } from './queries/brandKeys.web.graphql';
import { GET_FOCUS_POINT } from './queries/focusPoint.image.graphql';
import { GET_IMAGE_SOURCE_CATEGORIES } from './queries/imageSourceCategories.web.graphql';
import { GET_USERS } from './queries/users.auth.graphql';

const httpLink = new HttpLink({ uri: config.API_ONE_RECIPE });

const identifyRequestLink = new ApolloLink((operation, forward) => {
  operation.setContext(() => {
    let baseUri;
    switch (operation.operationName) {
      case getOperationName(GET_USERS):
        baseUri = config.API_ONE_AUTH;
        break;
      case getOperationName(GET_AUTHORS):
      case getOperationName(GET_AUTHOR):
      case getOperationName(GET_IMAGE_SOURCE_CATEGORIES):
      case getOperationName(SEARCH_ARTICLES):
      case getOperationName(GET_BRANDKEYS):
        baseUri = config.API_ONE_WEB;
        break;
      case getOperationName(GET_FOCUS_POINT):
        baseUri = config.API_IMAGE;
        break;

      default:
        baseUri = config.API_ONE_RECIPE;
    }
    return {
      uri: `${baseUri}?${operation.operationName}`,
    };
  });
  if (forward) {
    return forward(operation);
  }

  return null;
});

const authLink = setContext(async (_, context) => {
  const jwt = await auth.getJwt();
  if (!jwt) {
    return context;
  }
  return {
    ...context,
    headers: {
      ...context.headers,
      Authorization: `Bearer ${jwt}`,
      'x-client-name': `${config.ENV}-BONE-RECIPE-REACT`,
      'x-client-version': 'BONE-RECIPE-VERSION',
    },
  };
});

const paginatedFields = ['recipes', 'tags', 'categories'];

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: paginatedFields.reduce(
        (prev, fieldName) => ({
          ...(prev as any),
          [fieldName]: {
            keyArgs: ['where', 'first', 'orderBy'],
            merge: (existing = [], incoming: any, { args }: any) =>
              !args?.skip ? incoming : [...existing, ...incoming],
          },
        }),
        {} as any,
      ),
    } as any,
  },
});

export const client = new ApolloClient({
  link: ApolloLink.from([identifyRequestLink, authLink, httpLink]),
  cache,
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'all',
    },
    query: {
      errorPolicy: 'all',
    },
  },
  name: 'ONE-RECIPE',
  version: config.VERSION,
});
