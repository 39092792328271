import React, { FC } from 'react';
import { useStyles } from './styles';
import { Chip, capitalize } from '@mui/material';
import { Recipe } from '../../../../__generated__/queries-recipe';

interface TagsProps {
  recipeTags: Recipe.RecipeTags[];
  setRecipeTags?: (tags: Recipe.RecipeTags[]) => void;
}

export const Tags: FC<TagsProps> = ({ recipeTags, setRecipeTags }) => {
  const classes = useStyles();

  return (
    <div className={classes.tags}>
      {recipeTags
        .filter(({ tag }) => !!tag)
        .map(({ id, tag }, i) => (
          <Chip
            key={i}
            className={classes.tag}
            variant="outlined"
            label={capitalize(tag?.title || '')}
            onDelete={() => {
              setRecipeTags &&
                setRecipeTags([
                  ...recipeTags.map((recipeTag) => ({
                    ...recipeTag,
                    tag: recipeTag.id === id ? null : recipeTag.tag,
                  })),
                ]);
            }}
          />
        ))}
    </div>
  );
};
