import React, { FC, ChangeEvent, useState } from 'react';
import { useStyles } from './styles';
import {
  DialogContent,
  TextField,
  Toolbar,
  Typography,
  IconButton,
  AppBar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  capitalize,
  Tabs,
  Tab,
} from '@mui/material';
import {
  useDebounce,
  InfiniteList,
  useDialog,
  isDefined,
} from '@prismamedia/one-components';
import {
  Close,
  Label,
  Add,
  Restaurant,
  Clear,
  ShoppingCart,
  People,
  Store,
} from '@mui/icons-material';
import { Tags } from '../InformationsDrawer/Tags';
import { Recipe, GetTags } from '../../../__generated__/queries-recipe';
import { useGetTags } from '../../../apollo/queries/tags.recipe.graphql';
import { isAdmin } from '../../../utils/auth';
import { NEW_RECIPE_TAG_ID, NEW_TAG_ID } from '../utils';

const PAGINATION = 50;

interface TagsDialogProps {
  tags: Recipe.RecipeTags[];
  setTags?: (tags: Recipe.RecipeTags[]) => void;
}

export const TagsDialog: FC<TagsDialogProps> = ({ tags, setTags }) => {
  const classes = useStyles();
  const { closeDialog } = useDialog();
  const [searchValue, setSearchValue] = useState('');
  const [internalTags, setInternalTags] = useState<Recipe.RecipeTags[]>(tags);
  const debouncedSearch = useDebounce(searchValue, 500);
  const { data, loading, error, fetchMore } = useGetTags({
    first: PAGINATION,
    where: {
      title_contains: debouncedSearch,
      id_not_in: internalTags
        .map(({ tag }) => tag)
        .filter(isDefined)
        .map((tag) => tag.id),
    },
    fetchCount: false,
  });

  let listWithNewTag = data?.tags;
  if (
    isAdmin() &&
    debouncedSearch &&
    ![...(listWithNewTag || []), ...internalTags.map((tag) => tag.tag)].some(
      (tag) => tag?.title.toLowerCase() === debouncedSearch.toLowerCase(),
    )
  ) {
    listWithNewTag = [
      {
        __typename: 'Tag',
        id: NEW_TAG_ID,
        title: debouncedSearch,
        recipeTagCount: 0,
      },
      ...(listWithNewTag || []),
    ];
  }

  return (
    <>
      <AppBar color="default" position="static">
        <Toolbar>
          <Typography className={classes.title} variant="h6">
            Module de tags
          </Typography>
          <IconButton
            onClick={() => {
              setTags && setTags(internalTags);
              closeDialog && closeDialog();
            }}
            data-testid="button-module-tag"
            size="large"
          >
            <Close />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.wrapper}>
        <Tags recipeTags={internalTags} setRecipeTags={setInternalTags} />
        <div className={classes.textFieldWrapper}>
          <TextField
            className={classes.textField}
            label="Saisissez un tag"
            variant="standard"
            value={searchValue}
            onChange={(event: ChangeEvent<{ value: string }>) => {
              const { value } = event.target;
              setSearchValue(value);
            }}
          />
          {searchValue && (
            <IconButton
              className={classes.resetButton}
              onClick={() => {
                setSearchValue('');
              }}
              size="small"
            >
              <Clear />
            </IconButton>
          )}
        </div>

        <div className={classes.tabsWrapper}>
          <Typography className={classes.tabsTitle} variant="body2">
            Suggestions
          </Typography>
          <Tabs value={3} textColor="primary" indicatorColor="primary">
            <Tab label="People (0)" icon={<People />} disabled />
            <Tab label="Marques (0)" icon={<Store />} disabled />
            <Tab label="Produits (0)" icon={<ShoppingCart />} disabled />
            <Tab
              label={`Cuisine (${data?.tagCount || 0})`}
              icon={<Restaurant />}
            />
          </Tabs>
        </div>
        <List className={classes.list}>
          <InfiniteList
            list={listWithNewTag}
            rowHeight={48}
            loading={loading}
            error={error}
            rowRenderer={(tag: GetTags.Tags, index: number) => (
              <ListItem
                button
                data-testid={`list-item-${index}`}
                onClick={() => {
                  setInternalTags((prev) => [
                    ...(prev || []),
                    {
                      __typename: 'RecipeTag',
                      id: `${NEW_RECIPE_TAG_ID}_${prev?.length || 0}`,
                      tag,
                    },
                  ]);
                }}
              >
                <ListItemIcon>
                  {tag.id === NEW_TAG_ID ? (
                    <Add color="primary" />
                  ) : (
                    <Label color="primary" />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={
                    tag.id === NEW_TAG_ID
                      ? `Créer le tag "${capitalize(tag.title)}"`
                      : capitalize(tag.title)
                  }
                />
              </ListItem>
            )}
            fetchMore={({ startIndex }) =>
              fetchMore({ variables: { skip: startIndex } })
            }
          />
        </List>
      </DialogContent>
    </>
  );
};
