import React, { FC, useEffect } from 'react';
import { BrandMenu as OCBrandMenu } from '@prismamedia/one-components';
import { useGetBrandKeys } from '../../apollo/queries/brandKeys.web.graphql';
import { useGlobalBrandKey } from '../../utils/globalState';
import { BrandKey } from '../../__generated__/queries-web';

export const BrandMenu: FC = () => {
  const [brandKey, setBrandKey] = useGlobalBrandKey();
  const { data: brandKeysData } = useGetBrandKeys();

  useEffect(() => {
    if (brandKeysData && !brandKey) {
      setBrandKey(brandKeysData.categories[0]?.brandKey);
    }
  }, [brandKeysData, brandKey, setBrandKey]);

  return (
    <OCBrandMenu
      items={
        brandKeysData?.categories.map(({ id, brandKey: label }) => ({
          id,
          label,
          selected: label === brandKey,
        })) || []
      }
      onItemSelect={({ label }) => setBrandKey(label as BrandKey)}
    />
  );
};
