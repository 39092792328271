import { TextFieldProps } from '@mui/material';
import { SxProps } from '@mui/system';
import {
  AutocompleteItem,
  AutocompleteSelect,
} from '@prismamedia/one-components';
import { FC } from 'react';
import { searchImageSourceCategories } from '../../apollo/queries/imageSourceCategories.web.graphql';

interface ImageSourceSelectProps {
  label?: string;
  value?: AutocompleteItem;
  onChange?: (value?: AutocompleteItem) => void;
  className?: string;
  TextFieldProps?: TextFieldProps;
  sx?: SxProps;
}

export const ImageSourceSelect: FC<ImageSourceSelectProps> = ({
  value,
  onChange,
  className,
  label = "Source de l'image",
  TextFieldProps: textFieldProps,
  sx,
}) => {
  const fetchList = (search: string) =>
    searchImageSourceCategories(search).then((sources) =>
      sources?.reduce(
        (prev, { id, title, imageSources }) => [
          ...prev,
          { id, label: title, disabled: true },
          ...imageSources.map((child) => ({
            id: child.id,
            label: child.title,
            level: 1,
          })),
        ],
        [] as AutocompleteItem[],
      ),
    );

  return (
    <AutocompleteSelect
      sx={sx}
      label={label}
      value={value}
      className={className}
      fetchList={fetchList}
      onChange={onChange}
      TextFieldProps={textFieldProps}
    />
  );
};
