import { Chip, Typography } from '@mui/material';
import clsx from 'clsx';
import { FC } from 'react';
import { IngredientData, ParsedIngredient } from '../utils';
import { useStyles } from './styles';

interface ParsedIngredientsProps {
  error: string;
  tabIndex: number;
  ingredientData: IngredientData | undefined;
}

export const ParsedIngredients: FC<ParsedIngredientsProps> = ({
  error,
  tabIndex,
  ingredientData,
}) => {
  const classes = useStyles();

  const isUnparsed = (ingredient: ParsedIngredient) =>
    !!ingredientData?.unparsed_ingredients?.find(
      (unparsedIngredient) => unparsedIngredient === ingredient.ingredient,
    );

  if (error) {
    return (
      <Typography variant="caption" color="error">
        {error}
      </Typography>
    );
  }

  if (!ingredientData?.split_ingredients?.length) {
    return (
      <Typography variant="caption" className={classes.caption}>
        C'est ici que s'affiche{' '}
        {tabIndex === 0
          ? "l'aperçu de votre liste d'ingrédients"
          : 'la liste des ingrédients de référence'}
      </Typography>
    );
  }

  if (tabIndex === 0) {
    return (
      <>
        {ingredientData.split_ingredients.map((section, index) => (
          <div key={`section-${index}`}>
            <Typography className={classes.sectionTitle} variant="body2">
              {section.title}
            </Typography>
            {section?.ingredients?.map((ingredient, i) => (
              <Typography
                className={clsx(
                  classes.ingredient,
                  index > 0 && classes.subSectionIngredient,
                )}
                key={`ingredient-${i}`}
                variant="body2"
                color={isUnparsed(ingredient) ? 'error' : undefined}
              >
                •{' '}
                <b>
                  {ingredient.quantity !== '_' ? ingredient.quantity : ''}
                  {ingredient.unit ? ' ' : ''}
                  {ingredient.unit}
                </b>{' '}
                {ingredient.ingredient}
                {isUnparsed(ingredient) && ' (Mauvais formatage)'}
              </Typography>
            ))}
          </div>
        ))}
      </>
    );
  }

  return (
    <div className={classes.ingredientChips}>
      {ingredientData.split_ingredients
        .reduce<ParsedIngredient[]>(
          (prev, section) => [...prev, ...(section?.ingredients || [])],
          [],
        )
        .map((ingredient, i) => (
          <Chip
            className={classes.ingredientChip}
            key={`ingredient-${i}`}
            variant="outlined"
            disabled={!ingredient.referenceIngredient}
            color={isUnparsed(ingredient) ? 'error' : undefined}
            label={
              ingredient.referenceIngredient
                ? ingredient.referenceIngredient
                : `${ingredient.ingredient} (${
                    isUnparsed(ingredient)
                      ? 'Mauvais formatage'
                      : 'Aucune référence'
                  })`
            }
          />
        ))}
    </div>
  );
};
