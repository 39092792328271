import { gql } from '@apollo/client';
import { useCallback } from 'react';
import { client } from '..';
import {
  GetImageSourceCategoriesQuery,
  GetImageSourceCategoriesQueryVariables,
} from '../../__generated__/queries-web';
import { useQueryPromise } from '../../utils/useQueryPromise';

export const GET_IMAGE_SOURCE_CATEGORIES = gql`
  query GetImageSourceCategories(
    $where: ImageSourceCategoryWhereInput
    $sourceWhere: ImageSourceWhereInput
  ) {
    imageSourceCategories(first: 100, orderBy: title_ASC, where: $where) {
      id
      title
      imageSources(first: 100, orderBy: title_ASC, where: $sourceWhere) {
        id
        title
      }
    }
  }
`;

export const useGetImageSourceCategories = () => {
  const queryPromise = useQueryPromise<
    GetImageSourceCategoriesQuery,
    GetImageSourceCategoriesQueryVariables
  >(GET_IMAGE_SOURCE_CATEGORIES);

  return useCallback(
    (search: string) =>
      queryPromise({
        where: {
          imageSources_some: {
            title_contains: search,
          },
        },
        sourceWhere: {
          title_contains: search,
        },
      }).then(({ imageSourceCategories }) => imageSourceCategories),
    [queryPromise],
  );
};

export const searchImageSourceCategories = async (search: string) => {
  // can't use hook, used outside components
  const { data } = await client.query<GetImageSourceCategoriesQuery>({
    query: GET_IMAGE_SOURCE_CATEGORIES,
    variables: {
      where: {
        imageSources_some: {
          title_contains: search,
        },
      },
      sourceWhere: {
        title_contains: search,
      },
    },
  });
  return data?.imageSourceCategories;
};
