import {
  useGlobalBrandKey,
  useGlobalCategorySearchParams,
} from '../../utils/globalState';
import { GetCategoriesQueryVariables } from '../../__generated__/queries-recipe';

export const PAGINATION = 100;

export const useCategoriesVariables = (): GetCategoriesQueryVariables => {
  const [brandKey] = useGlobalBrandKey(true);
  const [searchParams] = useGlobalCategorySearchParams();

  return {
    first: PAGINATION,
    where: { brandKey, title_contains: searchParams.search },
    orderBy: ['createdAt_DESC'],
    fetchCount: true,
  };
};
