import {
  AutocompleteItem,
  HomeAppBar,
  SearchBar,
} from '@prismamedia/one-components';
import { format } from 'date-fns';
import { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useGetRecipesPromise } from '../../../apollo/queries/recipes.recipe.graphql';
import { AppDrawerMenu } from '../../../components/AppDrawerMenu';
import { BrandMenu } from '../../../components/BrandMenu';
import { paths, replaceParams } from '../../../routing';
import { auth } from '../../../utils/auth';
import { extractPath } from '../../../utils/extractPath';
import {
  useGlobalBrandKey,
  useGlobalRecipeSearchParams,
  useGlobalStatus,
} from '../../../utils/globalState';
import { recipeStatus } from '../../../utils/recipeStatus';
import { AdvancedSearch } from './AdvancedSearch';

export const ListAppBar: FC = () => {
  const history = useHistory();
  const getRecipes = useGetRecipesPromise();
  const [brandKey] = useGlobalBrandKey();
  const [status, setStatus] = useGlobalStatus();
  const [searchParams, setSearchParams] = useGlobalRecipeSearchParams();

  const fetchAutocompleteList = useCallback(
    async (search: string): Promise<AutocompleteItem[] | undefined> => {
      const searchTitlePath = extractPath(search);

      const data = await getRecipes({
        first: 5,
        orderBy: ['publishedAt_DESC'],
        where: {
          brandKey,
          ...(searchTitlePath
            ? { recipeUrls_some: { url: { path: searchTitlePath } } }
            : { title_contains: search }),
        },
      });

      return data.recipes?.map(({ id, title, publishedAt }) => ({
        id,
        label: title,
        secondaryLabel: publishedAt
          ? format(new Date(publishedAt), 'd MMM')
          : undefined,
      }));
    },
    [brandKey, getRecipes],
  );

  return (
    <HomeAppBar
      appDrawerMenu={<AppDrawerMenu />}
      brandMenu={<BrandMenu />}
      searchBar={
        <SearchBar
          placeholder="Rechercher une recette"
          setSearchParams={setSearchParams}
          searchParams={searchParams}
          autocomplete={{
            fetchList: fetchAutocompleteList,
            onSelect: ({ id }) => {
              id &&
                brandKey &&
                history.push(replaceParams(paths.DETAILS, { brandKey, id }));
            },
          }}
          advancedSearch={{ component: AdvancedSearch, props: { brandKey } }}
        />
      }
      currentUser={auth.user}
      disconnectUser={auth.logout}
      tabs={recipeStatus.map(({ id, textPlural }) => ({
        id,
        label: textPlural,
        selected: status.id === id,
      }))}
      onTabSelect={({ id }) => {
        const statusFound = recipeStatus.find(
          ({ id: statusId }) => statusId === id,
        );
        statusFound && setStatus(statusFound);
      }}
    />
  );
};
