import { ApolloProvider } from '@apollo/client';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  DialogDisplay,
  DialogProvider,
  MenuDisplay,
  MenuProvider,
  NotificationDisplay,
  NotificationProvider,
  TutorialProvider,
  UpdateNotification,
  GoogleAuthProvider,
} from '@prismamedia/one-components';
import React, { FC } from 'react';
import { initialize } from 'react-ga';
import { BrowserRouter, Route } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { QueryParamProvider } from 'use-query-params';
import { client } from './apollo';
import './App.css';
import './megadraft.css';
import { Routing } from './routing';
import { theme } from './theme';
import { fr } from 'date-fns/locale';
import { setDefaultOptions } from 'date-fns';

setDefaultOptions({ locale: fr });

initialize(config.GA_ID, {
  testMode: ['test', 'development'].includes(process.env.NODE_ENV),
});

export const App: FC = () => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <RecoilRoot>
        <CssBaseline>
          <GoogleAuthProvider>
            <ApolloProvider client={client}>
              <NotificationProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={fr}>
                  <BrowserRouter>
                    <QueryParamProvider ReactRouterRoute={Route}>
                      <DialogProvider>
                        <MenuProvider>
                          <TutorialProvider>
                            <Routing />
                            <MenuDisplay />
                            <NotificationDisplay />
                            <DialogDisplay />
                            <UpdateNotification />
                          </TutorialProvider>
                        </MenuProvider>
                      </DialogProvider>
                    </QueryParamProvider>
                  </BrowserRouter>
                </LocalizationProvider>
              </NotificationProvider>
            </ApolloProvider>
          </GoogleAuthProvider>
        </CssBaseline>
      </RecoilRoot>
    </ThemeProvider>
  </StyledEngineProvider>
);
