import {
  AutocompleteItem,
  countSearchParams,
  useOnMount,
} from '@prismamedia/one-components';
import { atom, SetterOrUpdater, useRecoilState } from 'recoil';
import { useQueryParam, JsonParam, StringParam } from 'use-query-params';
import {
  extractFromOrderBy,
  getOrderBy,
  Sort,
  sortMethods,
} from '../const/filters';
import { BrandKey } from '../__generated__/queries-web';
import { RecipeStatus, recipeStatus } from './recipeStatus';
import { useEffect } from 'react';
import { RecipeOrderByInput } from '../__generated__/queries-recipe';

interface RecipeSearchParams {
  search?: string;
  mainCategory?: string;
  difficulty?: string;
  calorieLevel?: string;
  estimatedCost?: string;
  maxPrepTime?: number;
  maxCookTime?: number;
  maxWaitTime?: number;
  ingredients?: string[];
  tags?: AutocompleteItem[];
}

const recipeSearchParamsAtom = atom<RecipeSearchParams>({
  key: 'recipeSearchParams',
  default: {},
});

export const useGlobalRecipeSearchParams = () => {
  const [searchUrl, setSearchUrl] = useQueryParam('search', JsonParam);
  const [searchParams, setSearchParams] = useRecoilState(
    recipeSearchParamsAtom,
  );

  useOnMount(() => {
    searchUrl && setSearchParams(searchUrl);
  });

  useEffect(() => {
    if (countSearchParams(searchParams)) {
      setSearchUrl(searchParams, 'replaceIn');
    } else {
      setSearchUrl(undefined, 'replaceIn');
    }
  }, [searchParams, setSearchUrl]);

  return [searchParams, setSearchParams] as [
    RecipeSearchParams,
    SetterOrUpdater<RecipeSearchParams>,
  ];
};

const brandKeyAtom = atom<BrandKey | undefined>({
  key: 'brandKey',
  default: undefined,
});

export const useGlobalBrandKey = (urlParam?: boolean) => {
  const [brandKeyUrl, setBrandKeyUrl] = useQueryParam('brand', StringParam);
  const [brandKey, setBrandKey] = useRecoilState(brandKeyAtom);

  useOnMount(() => {
    urlParam && brandKeyUrl && setBrandKey(brandKeyUrl as BrandKey);
  });

  useEffect(() => {
    urlParam && setBrandKeyUrl(brandKey, 'replaceIn');
  }, [brandKey, setBrandKeyUrl, urlParam]);

  return [brandKey, setBrandKey] as [
    BrandKey | undefined,
    SetterOrUpdater<BrandKey | undefined>,
  ];
};

const sortAtom = atom<Sort>({
  key: 'sort',
  default: {
    direction: 'desc',
    method: sortMethods[0],
  },
});

export const useGlobalSort = () => {
  const [orderByUrl, setOrderByUrl] = useQueryParam('orderBy', StringParam);
  const [sort, setSort] = useRecoilState(sortAtom);

  useOnMount(() => {
    if (orderByUrl) {
      const { method, direction } = extractFromOrderBy(
        orderByUrl as RecipeOrderByInput,
      );
      method && direction && setSort({ method, direction });
    }
  });

  useEffect(() => {
    const parsedSort = getOrderBy(sort);
    setOrderByUrl(
      parsedSort !== 'createdAt_DESC' ? parsedSort : undefined,
      'replaceIn',
    );
  }, [sort, setOrderByUrl]);

  return [sort, setSort] as [Sort, SetterOrUpdater<Sort>];
};

const statusAtom = atom<RecipeStatus>({
  key: 'status',
  default: recipeStatus[0],
});

export const useGlobalStatus = () => {
  const [statusUrl, setStatusUrl] = useQueryParam('status', StringParam);
  const [status, setStatus] = useRecoilState(statusAtom);

  useOnMount(() => {
    const foundStatus = recipeStatus.find((s) => s.id === statusUrl);
    foundStatus && setStatus(foundStatus);
  });

  useEffect(() => {
    setStatusUrl(
      status.id !== recipeStatus[0].id ? status.id : undefined,
      'replaceIn',
    );
  }, [status, setStatusUrl]);

  return [status, setStatus] as [RecipeStatus, SetterOrUpdater<RecipeStatus>];
};

interface CategorySearchParams {
  search?: string;
}

const categorySearchParamsAtom = atom<CategorySearchParams>({
  key: 'categorySearchParams',
  default: {},
});

export const useGlobalCategorySearchParams = () => {
  const [searchUrl, setSearchUrl] = useQueryParam('search', JsonParam);
  const [searchParams, setSearchParams] = useRecoilState(
    categorySearchParamsAtom,
  );

  useOnMount(() => {
    searchUrl && setSearchParams(searchUrl);
  });

  useEffect(() => {
    if (countSearchParams(searchParams)) {
      setSearchUrl(searchParams, 'replaceIn');
    } else {
      setSearchUrl(undefined, 'replaceIn');
    }
  }, [searchParams, setSearchUrl]);

  return [searchParams, setSearchParams] as [
    CategorySearchParams,
    SetterOrUpdater<CategorySearchParams>,
  ];
};
