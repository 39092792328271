import { fetchData } from '@prismamedia/one-components';

export interface IngredientData {
  split_ingredients?: IngredientSection[];
  unparsed_ingredients?: string[];
}

interface IngredientSection {
  title?: string;
  ingredients?: ParsedIngredient[];
}

export interface ParsedIngredient {
  ingredient?: string;
  quantity?: string;
  commonIngredient?: string;
  referenceIngredient?: string;
  unit?: string;
}

export const getParsedIngredients = (ingredients: string) =>
  fetchData<IngredientData>(
    `${config.API_INGREDIENT}/?ingredients=${encodeURIComponent(ingredients)}`,
  );
