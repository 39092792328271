import { useQuery, gql } from '@apollo/client';
import { useQueryPromise } from '../../utils/useQueryPromise';
import {
  GetTagsQuery,
  GetTagsQueryVariables,
} from '../../__generated__/queries-recipe';

const GET_TAGS = gql`
  query GetTags(
    $first: Int!
    $skip: Int
    $where: TagWhereInput!
    $fetchCount: Boolean!
  ) {
    tagCount(where: $where)
    tags(first: $first, skip: $skip, where: $where, orderBy: title_ASC) {
      id
      title
      recipeTagCount @include(if: $fetchCount)
    }
  }
`;

export const useGetTags = (variables: GetTagsQueryVariables) =>
  useQuery<GetTagsQuery, GetTagsQueryVariables>(GET_TAGS, {
    variables,
    notifyOnNetworkStatusChange: true,
  });

export const useGetTagsPromise = () =>
  useQueryPromise<GetTagsQuery, GetTagsQueryVariables>(GET_TAGS);
