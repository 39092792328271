import { gql } from '@apollo/client';

export const SEARCH_ARTICLES = gql`
  query SearchArticles(
    $where: ArticleSearchWhereInput
    $first: Int!
    $skip: Int
    $orderBy: [ArticleSearchOrderByInput!]
  ) {
    searchArticles(
      first: $first
      where: $where
      skip: $skip
      orderBy: $orderBy
    ) {
      id
      title
      body
      medias
      publishedAt
      status
      articleCreatorData {
        name
      }
      articleUrls(first: 1, orderBy: order_ASC) {
        url {
          path
          domain
        }
      }
    }
  }
`;
