import React, { FC } from 'react';
import { MenuItem, ListItemIcon, Tooltip } from '@mui/material';
import { GetRecipe } from '../../__generated__/queries-recipe';
import { getStatus, RecipeAction, getAction } from '../../utils/recipeStatus';
import { useUpdateRecipe } from '../../apollo/mutations/recipes.recipe.graphql';
import {
  useDialog,
  getRequiredErrorMessage,
  useMenu,
  useNotification,
  NotificationTypeEnum,
} from '@prismamedia/one-components';
import { formatDate } from '../../utils/formatDate';
import { formatISO } from 'date-fns';

interface ActionsMenuProps {
  recipe?: GetRecipe.Recipe;
  setLoading?: (loading: boolean) => void;
}

export const ActionsMenu: FC<ActionsMenuProps> = ({ recipe, setLoading }) => {
  const updateRecipe = useUpdateRecipe();
  const dialog = useDialog();
  const { pushNotification } = useNotification();
  const { closeMenu } = useMenu();

  const schedule = async (dateTime: Date) => {
    let res;
    if (recipe) {
      res = await updateRecipe(recipe.id, {
        status: 'Scheduled',
        publishedAt: formatISO(dateTime),
      });
      pushNotification({
        message: `${res.data?.updateRecipe?.title} sera publié le ${formatDate(
          dateTime,
        )}`,
        type: NotificationTypeEnum.success,
      });
    }
    if (!res) {
      throw new Error();
    }
  };

  const MenuElem = (action: RecipeAction, disabled: boolean) => (
    <MenuItem
      disabled={disabled}
      data-testid="menu-item-publish"
      onClick={async () => {
        closeMenu && closeMenu();
        if (!recipe) {
          return;
        }
        action.callBack && action.callBack({ dialog, schedule, recipe });
        if (action.goToStatus && !action.disableStatusChange) {
          setLoading && setLoading(true);
          const res = await updateRecipe(recipe.id, {
            status: action.goToStatus,
          });
          pushNotification({
            message: `${res.data?.updateRecipe?.title}${action.successNotification}`,
            type: NotificationTypeEnum.success,
          });
          setLoading && setLoading(false);
        }
      }}
    >
      <ListItemIcon>{action.icon || null}</ListItemIcon>
      {action.title}
    </MenuItem>
  );

  return (
    <>
      {getStatus(recipe?.status)?.actions.map((actionId, i) => {
        const action = getAction(actionId);
        const requiredFields = getRequiredErrorMessage(
          recipe,
          getStatus(action?.goToStatus)?.mandatoryFields,
        );
        if (requiredFields) {
          return (
            <Tooltip title={requiredFields} key={`action-${i}`}>
              <div>{action && MenuElem(action, true)}</div>
            </Tooltip>
          );
        }
        return (
          <div key={`action-${i}`}>{action && MenuElem(action, false)}</div>
        );
      })}
    </>
  );
};
