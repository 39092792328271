import React, { FC } from 'react';
import {
  AppDrawerMenu as OCAppDrawerMenu,
  AppName,
  BoneLink,
} from '@prismamedia/one-components';
import { useMediaQuery } from '@mui/material';
import { theme } from '../../theme';
import { useGetBrandKeys } from '../../apollo/queries/brandKeys.web.graphql';
import {
  ChevronRight,
  FormatListBulleted,
  Restaurant,
} from '@mui/icons-material';
import { useGlobalBrandKey } from '../../utils/globalState';
import { auth, isAdmin } from '../../utils/auth';
import { paths } from '../../routing';
import { useLocation } from 'react-router-dom';

export const AppDrawerMenu: FC = () => {
  const location = useLocation();
  const [brandKey, setBrandKey] = useGlobalBrandKey();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { data: brandKeysData } = useGetBrandKeys(!smallScreen);

  const topSection: BoneLink[] = [
    {
      label: 'Recettes',
      icon: <Restaurant />,
      selected: location.pathname === paths.HOME,
      href: paths.HOME,
    },
    ...(isAdmin()
      ? [
          {
            label: 'Catégories',
            icon: <FormatListBulleted />,
            selected: location.pathname === paths.CATEGORY_LIST,
            href: paths.CATEGORY_LIST,
          },
        ]
      : []),
    ...(smallScreen && brandKeysData
      ? brandKeysData.categories.map(({ brandKey: label }) => ({
          label,
          icon: <ChevronRight />,
          selected: label === brandKey,
          onClick: () => setBrandKey(label),
        }))
      : []),
  ];

  return (
    <OCAppDrawerMenu
      appName={AppName.Recipe}
      topSection={topSection}
      disconnectUser={auth.logout}
      overriddenAppUrls={{
        [AppName.Authors]: `${config.FRONT_URLS.ONE_WEB}/#/authors/list`,
        [AppName.Diapo]: `${config.FRONT_URLS.ONE_WEB}/#/slideshow/list?status=Published`,
        [AppName.Newsletter]: config.FRONT_URLS.ONE_NEWSLETTER,
        [AppName.People]: config.FRONT_URLS.ONE_PERSON,
        [AppName.Photo]: config.FRONT_URLS.ONE_PHOTOS,
        [AppName.Story]: config.FRONT_URLS.ONE_TOPIC,
        [AppName.Web]: `${config.FRONT_URLS.ONE_WEB}`,
      }}
    />
  );
};
