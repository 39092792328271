import React, { FC, HTMLAttributes } from 'react';
import { Typography } from '@mui/material';
import {
  CharCounterProps,
  getWordCount,
  parseDraftContent,
} from '@prismamedia/one-components';
import clsx from 'clsx';
import { useStyles } from './styles';

interface PropTypes {
  values: CharCounterProps[];
}

export const GlobalCharCounter: FC<
  PropTypes & HTMLAttributes<HTMLDivElement>
> = ({ values, className }) => {
  const classes = useStyles();
  const contents = values.map(({ type, content }) =>
    type === 'Draft' ? parseDraftContent(content) : content,
  );

  return (
    <Typography className={clsx(className, classes.wordCount)}>
      {getWordCount(...contents)}
    </Typography>
  );
};
