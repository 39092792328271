import { gql } from '@apollo/client';

export const RECIPE_FRAGMENT = gql`
  fragment Recipe on Recipe {
    id
    brandKey
    title
    publishedAt
    createdAt
    editedAt
    author
    authorName
    status
    medias
    cookTime
    prepTime
    waitTime
    instructions
    advice
    difficulty
    rawIngredients
    parsedIngredients
    calorieLevel
    estimatedCost
    yield
    lockedBy {
      id
      name
      email
      avatarUrl
    }
    lockedAt
    recipeCategories(first: 100, orderBy: position_ASC) {
      id
      position
      category {
        id
        title
      }
    }
    recipeTags(first: 100) {
      id
      tag {
        id
        title
      }
    }
    recipeUrls(first: 1, orderBy: position_ASC) {
      url {
        path
      }
    }
    recipeHistories(first: 100, orderBy: createdAt_DESC) {
      id
      createdAt
      user {
        id
        name
      }
      status
      recipe {
        id
      }
    }
  }
`;
