import { FieldValidator } from '@prismamedia/one-components';
import {
  CategoryCreateInput,
  CategoryUpdateInput,
  GetCategory,
} from '../../__generated__/queries-recipe';

export const saveMandatoryFields: FieldValidator<GetCategory.Category>[] = [
  {
    label: 'Titre',
    validate: ({ title }) => !!title,
  },
  {
    label: 'Catégorie parent',
    validate: ({ parent }) => !!parent,
  },
];

export const getCategoryUpdateInput = (
  category: GetCategory.Category,
): CategoryUpdateInput => ({
  title: category.title,
  subtitle: category.subtitle,
  status: category.status,
  medias: category.medias,
  body: category.body,
});

export const getCategoryCreateInput = (
  category: GetCategory.Category,
): CategoryCreateInput => ({
  title: category.title,
  subtitle: category.subtitle,
  status: category.status,
  brandKey: category.brandKey,
  medias: category.medias,
  body: category.body,
  left: 0,
  right: 0,
  level: (category.parent?.level || 0) + 1,
  parent: {
    connect: { id: category.parent?.id },
  },
});
