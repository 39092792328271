import React, { FC } from 'react';
import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';
import { replaceParams, paths } from '../../routing';
import { Fab, Tooltip } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useGlobalBrandKey } from '../../utils/globalState';

export const BrandKeySpeedDial: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [brandKey] = useGlobalBrandKey();

  return (
    <Tooltip title={`Créer une recette ${brandKey}`}>
      <Fab
        className={classes.speedDial}
        color="primary"
        href={brandKey && replaceParams(paths.NEW, { brandKey })}
        onClick={(e) => {
          e.preventDefault();
          brandKey && history.push(replaceParams(paths.NEW, { brandKey }));
        }}
      >
        <Add />
      </Fab>
    </Tooltip>
  );
};
