import React, { FC, MouseEventHandler } from 'react';
import { useStyles } from './styles';
import {
  Button,
  IconButton,
  Typography,
  MenuItem,
  ListItemIcon,
} from '@mui/material';
import { ArrowDownward, ArrowUpward, Check } from '@mui/icons-material';
import { sortMethods } from '../../../const/filters';
import { useGlobalSort } from '../../../utils/globalState';
import { useMenu } from '@prismamedia/one-components';

export const ListActions: FC = () => {
  const classes = useStyles();
  const [sort, setSort] = useGlobalSort();
  const { openMenu, closeMenu } = useMenu();

  const onButtonClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    openMenu(
      e.currentTarget,
      sortMethods.map((method) => (
        <MenuItem
          key={method.id}
          onClick={() => {
            setSort((prev) => ({ ...prev, method }));
            closeMenu();
          }}
        >
          <ListItemIcon>
            {sort.method.id === method.id ? <Check /> : null}
          </ListItemIcon>
          <Typography variant="inherit">{method.title}</Typography>
        </MenuItem>
      )),
    );
  };

  return (
    <div className={classes.actions}>
      <Button
        size="small"
        className={classes.sortButton}
        onClick={onButtonClick}
      >
        {sort.method.title}
      </Button>
      <IconButton
        size="small"
        className={classes.sortButton}
        onClick={() => {
          setSort((prev) => ({
            ...prev,
            direction: prev.direction === 'desc' ? 'asc' : 'desc',
          }));
        }}
      >
        {sort.direction === 'desc' ? <ArrowDownward /> : <ArrowUpward />}
      </IconButton>
    </div>
  );
};
