import { AutocompleteItem } from '@prismamedia/one-components';
import { useGetCategoriesPromise } from '../apollo/queries/categories.recipe.graphql';
import { GetCategories } from '../__generated__/queries-recipe';

const getSecondaryLabel = (count = 0, displayCount: boolean) =>
  displayCount ? `${count} recette${count > 1 ? 's' : ''}` : undefined;

const spreadCategoryChildren = (
  categories: GetCategories.Categories[],
  displayCount: boolean,
  disableCategories: string[],
  parents: GetCategories.Categories[],
  level = -1,
): AutocompleteItem[] =>
  parents
    .reduce(
      (prev, category) => [
        ...prev,
        {
          id: category.id,
          label: category.title,
          level,
          secondaryLabel: getSecondaryLabel(
            category.recipeCategoryCount,
            displayCount,
          ),
          disabled: disableCategories?.includes(category.id),
          additionnals: { category },
        },
        ...spreadCategoryChildren(
          categories,
          displayCount,
          disableCategories,
          categories.filter(({ parent }) => parent?.id === category.id),
          level + 1,
        ),
      ],
      [] as AutocompleteItem[],
    )
    .filter((parent) => parent.level !== -1);

export const useFetchCategories = (
  brandKey: string | null,
  disableCategories: string[] = [],
  displayCount = false,
  minLevel = 2,
) => {
  const getCategories = useGetCategoriesPromise();

  return async (search: string) => {
    const data = await getCategories({
      first: 1000,
      where: {
        title_contains: search,
        brandKey,
        level_gte: minLevel,
      },
      orderBy: ['title_ASC'],
      countWhere: {
        position: 1,
      },
      fetchCount: displayCount,
    });

    if (search) {
      return data?.categories
        ?.filter(({ level }) => level > minLevel)
        .map((category) => ({
          id: category.id,
          label: category.title,
          secondaryLabel: getSecondaryLabel(
            category.recipeCategoryCount,
            displayCount,
          ),
          disabled: disableCategories?.includes(category.id),
          additionnals: { category },
        }));
    }

    const categories = data?.categories || [];
    return spreadCategoryChildren(
      categories,
      displayCount,
      disableCategories,
      categories.filter(({ level }) => level === minLevel),
    );
  };
};
