import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  caption: {
    color: theme.palette.grey[400],
    lineHeight: 1,
  },
  sectionTitle: {
    marginTop: theme.spacing(2),
  },
  ingredient: {
    marginTop: theme.spacing(1),
  },
  subSectionIngredient: {
    marginLeft: theme.spacing(2),
  },
  ingredientChips: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-Start',
  },
  ingredientChip: {
    marginTop: theme.spacing(1),
  },
}));
