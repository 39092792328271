import React, { FC, useState, useEffect } from 'react';
import {
  AutocompleteItem,
  AutocompleteSelect,
} from '@prismamedia/one-components';
import {
  useGetAuthors,
  useGetAuthor,
} from '../../apollo/queries/authors.web.graphql';

interface AuthorSelectProps {
  label: string;
  authorId?: string | null;
  onSelect?: (authorId: string | null) => void;
  className?: string;
}

export const AuthorSelect: FC<AuthorSelectProps> = ({
  label,
  authorId,
  onSelect,
  className,
}) => {
  const [value, setValue] = useState<AutocompleteItem | undefined>(undefined);
  const getAuthors = useGetAuthors();
  const { data: authorData } = useGetAuthor(authorId || undefined);

  const fetchList = (search: string) =>
    getAuthors(search).then((authors) =>
      authors?.map(({ id, name }) => ({ id, label: name })),
    );

  useEffect(() => {
    setValue(
      authorId && authorData?.author
        ? { id: authorId, label: authorData.author.name }
        : undefined,
    );
  }, [authorData, authorId]);

  return (
    <AutocompleteSelect
      label={label}
      value={value}
      className={className}
      fetchList={fetchList}
      onChange={(item) => onSelect && onSelect(item?.id || null)}
      mandatoryInput
    />
  );
};
