import { alpha } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  statusButtonDisabled: {
    borderColor: `${alpha(theme.palette.common.white, 0.3)} !important`,
    color: `${alpha(theme.palette.common.white, 0.3)} !important`,
  },
  wordCountMessage: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginRight: theme.spacing(4),
  },
  globalCharCounter: {
    fontWeight: 500,
    marginLeft: theme.spacing(0.5),
  },
  requiredMessage: {
    marginLeft: 'auto',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flexShrink: 1,
  },
}));
