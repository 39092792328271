import {
  AcUnit,
  Block,
  CheckCircleOutline,
  Delete,
  Description,
  Public,
  RestoreFromTrash,
  Schedule,
  Visibility,
} from '@mui/icons-material';
import type { FieldValidator, OpenDialog } from '@prismamedia/one-components';
import { ScheduleDialog } from '@prismamedia/one-components';
import React, { ReactNode } from 'react';
import {
  GetRecipe,
  RecipeStatus as RecipeStatusId,
} from '../__generated__/queries-recipe';
import { getFrontUrl } from './getFrontUrl';
import { publishMandatoryFields, saveMandatoryFields } from './mandatoryFields';

export enum RecipeActionId {
  UNPUBLISH = 'UNPUBLISH',
  SEE_FRONT = 'SEE_FRONT',
  SEND_PUSH = 'SEND_PUSH',
  PUBLISH = 'PUBLISH',
  SCHEDULE = 'SCHEDULE',
  SET_AS_READY = 'SET_AS_READY',
  FREEZE = 'FREEZE',
  SET_AS_DRAFT = 'SET_AS_DRAFT',
  UNSCHEDULE = 'UNSCHEDULE',
  RESCHEDULE = 'RESCHEDULE',
  DELETE = 'DELETE',
  RESTORE = 'RESTORE',
}

export interface RecipeAction {
  id: RecipeActionId;
  title: string;
  icon?: ReactNode;
  callBack?: (props?: {
    recipe?: GetRecipe.Recipe;
    dialog?: {
      closeDialog: { (): void };
      openDialog: OpenDialog;
    };
    schedule?: (dateTime: Date) => Promise<void>;
  }) => void;
  goToStatus?: RecipeStatusId;
  disableStatusChange?: boolean;
  successNotification?: string;
}

export interface RecipeStatus {
  id: RecipeStatusId;
  textPlural: string;
  textSingular: string;
  actions: RecipeActionId[];
  mandatoryFields?: FieldValidator<GetRecipe.Recipe>[];
}

const recipeActions: RecipeAction[] = [
  {
    id: RecipeActionId.UNPUBLISH,
    title: 'Dépublier',
    icon: <Block />,
    goToStatus: 'Draft',
    successNotification: ' a été dépublié',
  },
  {
    id: RecipeActionId.SET_AS_DRAFT,
    title: 'Mettre en brouillon',
    icon: <Description />,
    goToStatus: 'Draft',
    successNotification: ' a été mis en brouillon',
  },
  {
    id: RecipeActionId.SEE_FRONT,
    title: 'Visualiser',
    icon: <Visibility />,
    callBack: (props) => {
      getFrontUrl(props?.recipe) &&
        window.open(getFrontUrl(props?.recipe), '_blank');
    },
  },
  {
    id: RecipeActionId.PUBLISH,
    title: 'Publier',
    icon: <Public />,
    goToStatus: 'Published',
    successNotification: ' a été publié',
  },
  {
    id: RecipeActionId.UNSCHEDULE,
    title: 'Déprogrammer',
    icon: <Block />,
    goToStatus: 'Draft',
    successNotification: ' a été déprogrammé',
  },
  {
    id: RecipeActionId.RESCHEDULE,
    title: 'Re-programmer',
    icon: <Schedule />,
    goToStatus: 'Scheduled',
    disableStatusChange: true,
    callBack: (props) => {
      props?.dialog?.openDialog(
        <ScheduleDialog
          closeDialog={props?.dialog.closeDialog}
          schedule={props?.schedule}
        />,
      );
    },
  },
  {
    id: RecipeActionId.SCHEDULE,
    title: 'Programmer',
    icon: <Schedule />,
    goToStatus: 'Scheduled',
    disableStatusChange: true,
    callBack: (props) => {
      props?.dialog?.openDialog(
        <ScheduleDialog
          closeDialog={props?.dialog.closeDialog}
          schedule={props?.schedule}
        />,
      );
    },
  },
  {
    id: RecipeActionId.SET_AS_READY,
    title: 'Mettre en prêt pour relecture',
    icon: <CheckCircleOutline />,
    goToStatus: 'Ready',
    successNotification: ' a été mis en prêt pour relecture',
  },
  {
    id: RecipeActionId.FREEZE,
    title: 'Geler',
    icon: <AcUnit />,
    goToStatus: 'Frozen',
    successNotification: ' a été gelé',
  },
  {
    id: RecipeActionId.DELETE,
    title: 'Supprimer',
    icon: <Delete />,
    goToStatus: 'Deleted',
    successNotification: ' a été supprimé',
  },
  {
    id: RecipeActionId.RESTORE,
    title: 'Restaurer',
    icon: <RestoreFromTrash />,
    goToStatus: 'Draft',
    successNotification: ' a été restauré',
  },
];

export const recipeStatus: RecipeStatus[] = [
  {
    id: 'Published',
    textPlural: 'Publiés',
    textSingular: 'Publié',
    actions: [RecipeActionId.SEE_FRONT, RecipeActionId.UNPUBLISH],
    mandatoryFields: publishMandatoryFields,
  },
  {
    id: 'Scheduled',
    textPlural: 'Programmés',
    textSingular: 'Programmé',
    actions: [
      RecipeActionId.SEE_FRONT,
      RecipeActionId.RESCHEDULE,
      RecipeActionId.UNSCHEDULE,
    ],
    mandatoryFields: publishMandatoryFields,
  },
  {
    id: 'Ready',
    textPlural: 'Prêts',
    textSingular: 'Prêt',
    actions: [
      RecipeActionId.PUBLISH,
      RecipeActionId.SCHEDULE,
      RecipeActionId.FREEZE,
      RecipeActionId.SET_AS_DRAFT,
    ],
    mandatoryFields: saveMandatoryFields,
  },
  {
    id: 'Frozen',
    textPlural: 'Gelés',
    textSingular: 'Gelé',
    actions: [
      RecipeActionId.PUBLISH,
      RecipeActionId.SCHEDULE,
      RecipeActionId.SET_AS_READY,
      RecipeActionId.SET_AS_DRAFT,
    ],
    mandatoryFields: saveMandatoryFields,
  },
  {
    id: 'Draft',
    textPlural: 'Brouillons',
    textSingular: 'Brouillon',
    actions: [
      RecipeActionId.PUBLISH,
      RecipeActionId.SCHEDULE,
      RecipeActionId.SET_AS_READY,
      RecipeActionId.FREEZE,
      RecipeActionId.DELETE,
    ],
    mandatoryFields: saveMandatoryFields,
  },
  {
    id: 'Deleted',
    textPlural: 'Corbeille',
    textSingular: 'Corbeille',
    actions: [RecipeActionId.RESTORE],
    mandatoryFields: saveMandatoryFields,
  },
];

export const getAction = (id: RecipeActionId) =>
  recipeActions.find((action) => action.id === id);

export const getStatus = (id?: string | null) =>
  recipeStatus.find((s) => id === s.id);
