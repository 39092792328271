import React, { FC } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import clsx from 'clsx';
import { useStyles } from './styles';
import { CharCounter } from '@prismamedia/one-components';

export const TextFieldWordCount: FC<TextFieldProps> = ({
  value,
  className,
  ...props
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(className, classes.wrapper)}>
      <TextField value={value || ''} {...props} />
      <CharCounter content={value as string} />
    </div>
  );
};
