import { useQuery, gql } from '@apollo/client';
import { useCallback } from 'react';
import { useQueryPromise } from '../../utils/useQueryPromise';
import {
  GetAuthorQuery,
  GetAuthorQueryVariables,
  GetAuthorsQuery,
  GetAuthorsQueryVariables,
} from '../../__generated__/queries-web';

export const GET_AUTHORS = gql`
  query GetAuthors($where: AuthorWhereInput!) {
    authors(first: 100, where: $where, orderBy: name_ASC) {
      id
      name
    }
  }
`;

export const useGetAuthors = () => {
  const queryPromise = useQueryPromise<
    GetAuthorsQuery,
    GetAuthorsQueryVariables
  >(GET_AUTHORS);

  return useCallback(
    (search: string) =>
      queryPromise({ where: { name_contains: search } }).then(
        ({ authors }) => authors,
      ),
    [queryPromise],
  );
};

export const GET_AUTHOR = gql`
  query GetAuthor($where: AuthorWhereUniqueInput!) {
    author(where: $where) {
      id
      name
    }
  }
`;

export const useGetAuthor = (id?: string) =>
  useQuery<GetAuthorQuery, GetAuthorQueryVariables>(GET_AUTHOR, {
    variables: { where: { id } },
    skip: !id,
  });
