import makeStyles from '@mui/styles/makeStyles';
import { green } from '@mui/material/colors';

export const CARD_HEIGHT = 150;
export const CARD_HEIGHT_SMALL_SCREEN = 370;

export const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    height: CARD_HEIGHT,
    maxWidth: 1200,
    margin: '0 auto',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      height: CARD_HEIGHT_SMALL_SCREEN,
    },
  },
  cardLoading: {
    opacity: 0.5,
  },
  actionArea: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  media: {
    height: '100%',
    flexBasis: 250,
    flexShrink: 0,
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      width: '100%',
      flexBasis: 150,
    },
  },
  cardActions: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      marginTop: 'auto',
      flexDirection: 'row-reverse',
    },
  },
  lockButton: {
    marginTop: 'auto',
    marginLeft: '0 !important',
    [theme.breakpoints.down('sm')]: {
      marginRight: 'auto',
    },
  },
  title: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
      maxHeight: 64,
      overflow: 'hidden',
    },
  },
  status: {
    color: green[400],
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  mediaIcons: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));
