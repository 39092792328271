import {
  IframelyMedia,
  MEDIA_TYPES_LABEL,
  MediaFormat,
  fetchData,
  getCropZoneParam,
  getFirstMedia,
  getImageFromMedias,
  getMediasSite,
  getVideoThumbnail,
  parseMedias,
  resizeImage,
  resizer,
} from '@prismamedia/one-components';
import { RawDraftContentBlock, RawDraftContentState } from 'draft-js';
import { startsWith as _startsWith, when as _when, find } from 'ramda';
import {
  Article,
  ArticleFormat,
  ArticleStatus,
  BrandKey,
} from '../../__generated__/queries-web';
import { auth } from '../../utils/auth';

interface SlideshowData {
  id: string;
  description: RawDraftContentState | null;
  hasButton: boolean;
  buttonUrl: string | null;
  buttonTitle: string | null;
  buttonRel: string | null;
}

interface Crop {
  x: number;
  y: number;
  width: number;
  height: number;
}

interface MediaBlockData {
  caption: string;
  iframely: any;
  slideshow?: SlideshowData;
  focusPoint: {
    x: number | null;
    y: number | null;
    relativeX: number | null;
    relativeY: number | null;
  };
  crop?: Crop;
  type: 'media';
}

type MediaBlock = RawDraftContentBlock & {
  data: MediaBlockData;
};

interface ArticleUrl {
  url: {
    path: string;
    domain: string;
  };
}

export const getArticleUrl = (articleUrls?: ArticleUrl[]): string => {
  if (!articleUrls?.length) return '';

  const [first] = articleUrls;
  let { domain } = first.url;
  if (
    domain.startsWith('www') &&
    !domain.endsWith('/') &&
    !first.url.path.startsWith('/')
  ) {
    domain = `${domain}/`;
  }
  return `https://${domain}${first.url.path}`;
};

export const baseRecipesURLs: Record<string, string> = {
  FAC: 'https://www.femmeactuelle.fr',
  CAC: 'https://www.cuisineactuelle.fr',
};

export const standardizeArticleUrl = (url: string | null): string | null => {
  if (!url) return null;
  return !url.startsWith('http') ? `https://${url}` : url;
};

const getUrlForDiapo = (domain: string, path: string) => {
  const removeSlash = _when(_startsWith('/'), (data) => data.slice(1));
  return `https://${removeSlash(domain)}/${removeSlash(path)}`;
};

const embedMediaFromArticle = async (
  article: Pick<Article, 'articleUrls' | 'medias'>,
  articleFormat: ArticleFormat,
) => {
  let mediaUrl = '';

  if (articleFormat === 'Slideshow') {
    const { domain, path } = article.articleUrls[0].url;
    mediaUrl = getUrlForDiapo(domain, path);
  } else if (['Video', 'Rich'].includes(articleFormat)) {
    const media = getFirstMedia(parseMedias(article.medias), MediaFormat.Video);
    if (media) {
      mediaUrl = media.iframely.meta.canonical;
    }
  }

  if (mediaUrl) {
    return embedImage(mediaUrl);
  }
};

const statusToLabel = (
  format: ArticleFormat,
): Record<ArticleStatus, string> => {
  const videoStatus = format === 'Video';
  return {
    ['Published']: videoStatus ? 'Vidéo Publique' : 'Publié',
    ['Scheduled']: 'Programmé',
    ['Draft']: 'Brouillon',
    ['Frozen']: 'Gelé',
    ['Ready']: videoStatus ? 'Vidéo Prête' : 'Prêt',
    ['Deleted']: 'Corbeille',
    ['Pending']: 'En cours',
    ['Proposal']: 'En proposition',
    ['Todo']: 'A faire',
    ['Private']: videoStatus ? 'Vidéo Privée' : 'Privé',
  };
};

const getImageFromArticleBody = (
  body: string | null,
  withCrop: boolean,
): string | undefined => {
  if (body) {
    const parsed = JSON.parse(body);
    if (!Array.isArray(parsed)) {
      const media = find((block: MediaBlock) => block.data.type === 'media')(
        parsed.blocks,
      );
      const path = media?.data.iframely.meta.url;
      if (!withCrop) {
        return resizer.fit({
          origin: path,
          width: 350,
          height: 150,
        });
      }
      const crop = media?.data.crop;
      return resizer.fit({
        origin: path,
        width: 350,
        height: 150,
        parameters: getCropZoneParam(crop),
      });
    }
  }
};

export const getItemToRender = (
  brandKey: BrandKey,
  item: any,
  pluginType?: string,
) => {
  switch (pluginType) {
    case MEDIA_TYPES_LABEL.ARTICLES:
      return {
        chip: item.brandKey,
        id: item.id,
        image:
          getImageFromMedias(item.medias)?.mediaForDisplay ||
          item?.imageSrc?.mediaForDisplay,
        publicUrl: standardizeArticleUrl(item?.contextualizedUrl) || item?.url,
        publishDate: item.publishedAt,
        text: item.articleCreatorData?.name,
        title: item.title,
      };
    case MEDIA_TYPES_LABEL.RECIPES:
      return {
        chip: item.brandKey,
        id: item.id,
        image:
          getImageFromMedias(item.medias)?.mediaForDisplay ||
          item?.imageSrc?.mediaForDisplay,
        publicUrl:
          item?.url || baseRecipesURLs[brandKey] + item.recipeUrls[0].url?.path,
        publishDate: item.publishedAt,
        text: item.authorName,
        title: item.title,
      };
    case MEDIA_TYPES_LABEL.VIDEOS:
      return {
        chip: item.brandKey,
        id: item.id,
        image: getVideoThumbnail(item.medias),
        publicUrl: standardizeArticleUrl(item?.contextualizedUrl),
        publishDate: item.publishedAt,
        site: getMediasSite(item.medias)?.[0],
        status: `${(statusToLabel('Video') as any)[item.status]} le`,
        text: item.articleCreatorData?.name,
        title: item.title,
      };
    case MEDIA_TYPES_LABEL.SLIDESHOWS:
      return {
        chip: item.brandKey,
        id: item.id,
        image: getImageFromArticleBody(item.body, false),
        publicUrl: standardizeArticleUrl(item?.contextualizedUrl),
        publishDate: item.publishedAt,
        text: item.articleCreatorData?.name,
        title: item.title,
      };
    default:
      return {
        id: 'no-plugin-type',
      };
  }
};

const getRecipeMediaUrl = (medias: string | null) => {
  const imageSrc = getImageFromMedias(medias);

  if (imageSrc?.media) {
    return imageSrc.media.iframely.meta.canonical;
  }
};

export const getItemToIframely = async (item: any, pluginType: string) => {
  switch (pluginType) {
    case MEDIA_TYPES_LABEL.ARTICLES:
      return await embedMediaFromArticle(item, 'Rich');
    case MEDIA_TYPES_LABEL.RECIPES:
      return await embedImage(getRecipeMediaUrl(item.medias));
    case MEDIA_TYPES_LABEL.VIDEOS:
      return await embedMediaFromArticle(item, 'Video');
    case MEDIA_TYPES_LABEL.SLIDESHOWS:
      return await embedMediaFromArticle(item, 'Slideshow');
  }
};

const uploadImage = async (file: File, brandKey: string) => {
  const data = new FormData();
  data.append('image', file);
  data.append('namespace', brandKey.toLowerCase());

  const jwt = await auth.getJwt();
  return fetchData<any>(`${config.API_ONE_IMAGE}/api/images`, {
    method: 'POST',
    body: data,
    headers: { Authorization: `Bearer ${jwt}` },
  });
};

export const embedImage = (url: string) =>
  fetchData<IframelyMedia | any>(
    `${config.API_ONE_IFRAMELY}/iframely?uri=${encodeURIComponent(url)}`,
  );

export const uploadAndEmbedImage = async (file: File, brandKey: string) => {
  const resizedFile = await resizeImage(file, 3000, 3000);

  const uploadResult = await uploadImage(resizedFile, brandKey);
  if (!uploadResult) {
    return false;
  }

  return embedImage(uploadResult.url);
};

export const postImages = (brandKey: string) => (files: File[]) =>
  Promise.all(
    Array.from(files).map((file) => uploadAndEmbedImage(file, brandKey)),
  );

export const SEARCH_PAGE_RESULT = 50;
export const DEFAULT_PAGE_RESULT = 25;
