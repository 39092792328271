import React, { FC, useCallback, useState, useEffect } from 'react';
import { Typography, TextField } from '@mui/material';
import { useStyles } from './styles';

interface TimeInputProps {
  label: string;
  value: number;
  onChange?: (value: number) => void;
  className?: string;
}

export const TimeInput: FC<TimeInputProps> = ({
  label,
  value,
  onChange,
  className,
}) => {
  const classes = useStyles();
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);

  useEffect(() => {
    setDays(Math.floor(value / (60 * 60 * 24)));
    setHours(Math.floor(((value / 60) % (60 * 24)) / 60));
    setMinutes(Math.floor(((value / 60) % (60 * 24)) % 60));
  }, [value]);

  const onBlur = useCallback(() => {
    onChange?.((minutes + hours * 60 + days * 24 * 60) * 60);
  }, [days, hours, minutes, onChange]);

  return (
    <div className={className}>
      <Typography variant="body2">{label}</Typography>
      <div className={classes.inputs}>
        <TextField
          label="Jours"
          variant="standard"
          type="number"
          value={days}
          onChange={(e) => {
            const val = Number(e.target.value);
            setDays((prev) => (val >= 0 ? val : prev));
          }}
          onBlur={onBlur}
        />
        <TextField
          label="Heures"
          variant="standard"
          type="number"
          value={hours}
          onChange={(e) => {
            const val = Number(e.target.value);
            setHours((prev) => (val >= 0 ? val : prev));
          }}
          onBlur={onBlur}
        />
        <TextField
          label="Minutes"
          variant="standard"
          type="number"
          value={minutes}
          onChange={(e) => {
            const val = Number(e.target.value);
            setMinutes((prev) => (val >= 0 ? val : prev));
          }}
          onBlur={onBlur}
        />
      </div>
    </div>
  );
};
