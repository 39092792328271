import { Description } from '@mui/icons-material';
import {
  ActionStyle,
  ActionType,
  draftActions as OCDraftActions,
  StylableLink,
} from '@prismamedia/one-components';
import { DraftActionType } from '@prismamedia/one-components/build/types/MaterialDraft/DraftActions';
import { createTypeStrategy } from 'megadraft';
import { InternalLink } from '../components/MediaInput/InternalLink';

enum PossibleActions {
  BOLD = 'BOLD',
  ITALIC = 'ITALIC',
  STRIKETHROUGH = 'STRIKETHROUGH',
  LINK = 'LINK',
  INTERNAL_LINK = 'INTERNAL_LINK',
  TITLE = 'TITLE',
  HEADLINE = 'HEADLINE',
  LIST = 'LIST',
  ORDERED_LIST = 'ORDERED_LIST',
  BLOCKQUOTE = 'BLOCKQUOTE',
  SEPARATOR = 'SEPARATOR',
}

export const draftActions: Record<PossibleActions, DraftActionType> = {
  ...OCDraftActions,
  [PossibleActions.INTERNAL_LINK]: {
    action: {
      type: ActionType.entity,
      label: 'InternalLink',
      style: ActionStyle.LINK,
      entity: 'INTERNAL_LINK',
      icon: Description,
    },
    entity: {
      INTERNAL_LINK: InternalLink,
    },
    decorator: {
      strategy: createTypeStrategy('INTERNAL_LINK'),
      component: StylableLink(),
    },
  },
};
