import React, { FC } from 'react';
import { useStyles } from './styles';
import {
  ChipsInput,
  SimpleSelect,
  AdvancedSearchProps,
} from '@prismamedia/one-components';
import { CategorySelect } from '../../../../components/CategorySelect';
import clsx from 'clsx';
import {
  calorieLevels,
  difficulties,
  estimatedCosts,
  timeValues,
} from '../../../../const/selectsValues';
import { useGetCategory } from '../../../../apollo/queries/categories.recipe.graphql';
import { Divider } from '@mui/material';
import { TagsSelect } from '../../../../components/TagsSelect';

export const AdvancedSearch: FC<AdvancedSearchProps> = ({
  searchParams,
  updateSearchParam,
  brandKey,
}) => {
  const classes = useStyles();
  const { data } = useGetCategory(searchParams.mainCategory);

  return (
    <>
      <div className={classes.section}>
        <CategorySelect
          label="Catégorie principale"
          category={data?.category}
          onSelect={(cat) => {
            updateSearchParam('mainCategory')(cat?.id);
          }}
          className={classes.input}
          brandKey={brandKey}
          displayCount
        />
        <TagsSelect
          value={searchParams.tags}
          onChange={updateSearchParam('tags')}
          className={classes.input}
        />
        <ChipsInput
          label="Ingrédients"
          value={searchParams.ingredients}
          onChange={updateSearchParam('ingredients')}
          className={classes.input}
        />
      </div>
      <Divider />
      <div className={classes.section}>
        <SimpleSelect
          className={clsx(classes.input, classes.flexInput)}
          label="Difficulté"
          variant="standard"
          options={difficulties}
          value={searchParams.difficulty}
          onChange={updateSearchParam('difficulty')}
        />
        <SimpleSelect
          className={clsx(classes.input, classes.flexInput)}
          label="Niveau de prix"
          variant="standard"
          options={estimatedCosts}
          value={searchParams.estimatedCost}
          onChange={updateSearchParam('estimatedCost')}
        />
        <SimpleSelect
          className={clsx(classes.input, classes.flexInput)}
          label="Taux de calories"
          variant="standard"
          options={calorieLevels}
          value={searchParams.calorieLevel}
          onChange={updateSearchParam('calorieLevel')}
        />
      </div>
      <Divider />
      <div className={classes.section}>
        <SimpleSelect
          className={clsx(classes.input, classes.flexInput)}
          label="Temps de préparation"
          variant="standard"
          options={timeValues}
          value={
            searchParams.maxPrepTime ? String(searchParams.maxPrepTime) : ''
          }
          onChange={(val) => updateSearchParam('maxPrepTime')(Number(val))}
        />
        <SimpleSelect
          className={clsx(classes.input, classes.flexInput)}
          label="Temps de cuisson"
          variant="standard"
          options={timeValues}
          value={
            searchParams.maxCookTime ? String(searchParams.maxCookTime) : ''
          }
          onChange={(val) => updateSearchParam('maxCookTime')(Number(val))}
        />
        <SimpleSelect
          className={clsx(classes.input, classes.flexInput)}
          label="Temps d'attente"
          variant="standard"
          options={timeValues}
          value={
            searchParams.maxWaitTime ? String(searchParams.maxWaitTime) : ''
          }
          onChange={(val) => updateSearchParam('maxWaitTime')(Number(val))}
        />
      </div>
    </>
  );
};
