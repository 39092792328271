import { createTheme, Theme } from '@mui/material';
import { pink } from '@mui/material/colors';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const theme = createTheme({
  palette: {
    primary: pink,
    secondary: {
      light: '#000',
      main: '#fff',
      dark: '#fff',
      contrastText: pink[400],
    },
    background: {
      default: '#fafafa',
    },
  },
});
