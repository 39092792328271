import { EntityInputs } from '@prismamedia/one-components';
import { getSelectionText } from 'draftjs-utils';
import type { FC } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { BrandKey } from '../../../__generated__/queries-web';
import { LinkInput } from '../LinkInput';
import { baseRecipesURLs, getArticleUrl } from '../utils';
import { MultimediaPluginLink } from './MultimediaPluginLink';

const InternalLinkWithRouter: FC<
  EntityInputs & { url: string; entity: any } & RouteComponentProps<{
      brandKey: BrandKey;
    }>
> = ({ url, removeEntity, cancelEntity, editorState, setEntity, match }) => {
  const selectedText = getSelectionText(editorState);

  return (
    <div>
      <LinkInput
        cancelEntity={cancelEntity}
        removeEntity={removeEntity}
        url={url}
      />
      {!url && (
        <MultimediaPluginLink
          searchText={selectedText}
          brandKey={match.params.brandKey}
          onLinkSelected={(link) => {
            const isRecipe = link.__typename === 'Recipe';
            const isSlideshow = link.format === 'Slideshow';
            const publicUrl = isRecipe
              ? baseRecipesURLs[match.params.brandKey] +
                link.recipeUrls[0].url?.path
              : isSlideshow
              ? link.contextualizedUrl
              : getArticleUrl(link.articleUrls);
            setEntity({ url: publicUrl });
            cancelEntity();
          }}
          onClose={() => {
            cancelEntity();
          }}
        />
      )}
    </div>
  );
};

export const InternalLink = withRouter(InternalLinkWithRouter);
