import { gql } from '@apollo/client';

export const CATEGORY_FRAGMENT = gql`
  fragment Category on Category {
    id
    title
    level
    createdAt
    slug
    status
    subtitle
    medias
    body
    updatedAt
    brandKey
    parent {
      id
      title
      level
    }
    children(first: 1000) {
      id
      title
    }
  }
`;
