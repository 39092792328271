import { Divider, Typography, useMediaQuery } from '@mui/material';
import {
  InfiniteList,
  countSearchParams,
  getSpacing,
} from '@prismamedia/one-components';
import clsx from 'clsx';
import { FC, useEffect } from 'react';
import { GetRecipes } from '../../__generated__/queries-recipe';
import { useGetRecipes } from '../../apollo/queries/recipes.recipe.graphql';
import { BrandKeySpeedDial } from '../../components/BrandKeySpeedDial';
import { theme } from '../../theme';
import {
  useGlobalBrandKey,
  useGlobalRecipeSearchParams,
  useGlobalStatus,
} from '../../utils/globalState';
import { ListActions } from './ListActions';
import { ListAppBar } from './ListAppBar';
import { RecipeCard } from './RecipeCard';
import { CARD_HEIGHT, CARD_HEIGHT_SMALL_SCREEN } from './RecipeCard/styles';
import { useStyles } from './styles';
import { PAGINATION, useRecipeVariables } from './utils';

export const RecipeListPage: FC = () => {
  const classes = useStyles();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [status] = useGlobalStatus();
  const [brandKey] = useGlobalBrandKey(true);
  const [searchParams] = useGlobalRecipeSearchParams();
  const variables = useRecipeVariables();

  useEffect(() => {
    // eslint-disable-next-line immutable/no-mutation
    document.title = 'ONE-Recipe';
  }, []);

  const { data, loading, error, fetchMore } = useGetRecipes(
    variables,
    !brandKey || !status,
  );

  return (
    <>
      <ListAppBar />
      <div
        className={clsx(
          classes.listWrapper,
          countSearchParams(searchParams) &&
            data?.recipeCount &&
            classes.listWrapperWithCounter,
        )}
      >
        {!!countSearchParams(searchParams) && !!data?.recipeCount && (
          <div className={classes.searchCounterWrapper}>
            <div className={classes.searchCounter}>
              <Typography className={classes.searchCounterText}>
                {data?.recipeCount} résultat
                {data?.recipeCount > 1 ? 's' : ''} de recherche
              </Typography>
              <Divider />
            </div>
          </div>
        )}
        <ListActions />
        <InfiniteList
          list={data?.recipes}
          rowRenderer={(recipe: GetRecipes.Recipes) => (
            <RecipeCard recipe={recipe} />
          )}
          rowHeight={
            (smallScreen ? CARD_HEIGHT_SMALL_SCREEN : CARD_HEIGHT) +
            getSpacing(theme, 2)
          }
          className={classes.list}
          threshold={PAGINATION / 2}
          loading={loading || !brandKey}
          error={error}
          fetchMore={({ startIndex }) =>
            fetchMore({ variables: { skip: startIndex } })
          }
        />
      </div>
      <BrandKeySpeedDial />
    </>
  );
};
