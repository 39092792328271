import { Unit } from '@prismamedia/one-brandkey';
import { Auth } from '@prismamedia/one-components';
import { Role } from '@prismamedia/one-user';

export const auth = new Auth(config.API_ONE_AUTH);

export const isAdmin = () =>
  auth.user &&
  (auth.user.isSuperAdmin() ||
    auth.user.hasRoleOnUnit(Role.Admin, Unit.PFEM) ||
    auth.user.hasRoleOnUnit(Role.WebAdmin, Unit.PFEM));
