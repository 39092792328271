import { useMemo } from 'react';
import { getOrderBy } from '../../const/filters';
import { extractPath } from '../../utils/extractPath';
import {
  useGlobalBrandKey,
  useGlobalRecipeSearchParams,
  useGlobalSort,
  useGlobalStatus,
} from '../../utils/globalState';
import { GetRecipesQueryVariables } from '../../__generated__/queries-recipe';

export const PAGINATION = 20;

export const useRecipeVariables = (): GetRecipesQueryVariables => {
  const [status] = useGlobalStatus();
  const [sort] = useGlobalSort();
  const [brandKey] = useGlobalBrandKey(true);
  const [searchParams] = useGlobalRecipeSearchParams();

  const searchUrlPath = extractPath(searchParams.search || '');

  return useMemo(
    () => ({
      first: PAGINATION,
      where: {
        brandKey,
        status: status.id,
        title_contains: searchUrlPath ? undefined : searchParams.search,
        recipeUrls_some: searchUrlPath
          ? { url: { path: searchUrlPath } }
          : undefined,
        recipeCategories_some: searchParams.mainCategory
          ? {
              category: { id: searchParams.mainCategory },
              position: 1,
            }
          : undefined,
        difficulty: searchParams.difficulty,
        estimatedCost: searchParams.estimatedCost,
        calorieLevel: searchParams.calorieLevel,
        prepTime_lte: searchParams.maxPrepTime,
        cookTime_lte: searchParams.maxCookTime,
        waitTime_lte: searchParams.maxWaitTime,
        AND: [
          ...(searchParams.tags?.map((tag) => ({
            recipeTags_some: {
              tag: {
                id: tag.id,
              },
            },
          })) || []),
          ...(searchParams.ingredients?.map((ingredient) => ({
            parsedIngredients_contains: `"referenceIngredient":"${ingredient}"`,
          })) || []),
        ],
      },
      orderBy: [getOrderBy(sort)],
    }),
    [
      brandKey,
      searchParams.calorieLevel,
      searchParams.difficulty,
      searchParams.estimatedCost,
      searchParams.ingredients,
      searchParams.mainCategory,
      searchParams.maxCookTime,
      searchParams.maxPrepTime,
      searchParams.maxWaitTime,
      searchParams.search,
      searchParams.tags,
      searchUrlPath,
      sort,
      status.id,
    ],
  );
};
