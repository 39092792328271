import { capitalize } from '@mui/material';
import {
  AutocompleteItem,
  ChipsInput,
  pluralize,
} from '@prismamedia/one-components';
import React, { FC } from 'react';
import { useGetTagsPromise } from '../../apollo/queries/tags.recipe.graphql';

interface TagsSelectProps {
  className?: string;
  value?: AutocompleteItem[];
  onChange?: (value: AutocompleteItem[]) => void;
}

export const TagsSelect: FC<TagsSelectProps> = ({
  className,
  value,
  onChange,
}) => {
  const getTags = useGetTagsPromise();

  const fetchList = (search: string) =>
    getTags({
      where: { title_contains: search },
      first: 50,
      fetchCount: true,
    }).then((data) =>
      data.tags.map((tag) => ({
        id: tag.id,
        label: capitalize(tag.title),
        secondaryLabel: `${tag.recipeTagCount} ${pluralize(
          'recette',
          tag.recipeTagCount,
        )}`,
      })),
    );

  return (
    <ChipsInput
      label="Tags"
      value={value}
      onChange={onChange}
      className={className}
      autocomplete={{ mandatoryInput: true, fetchList }}
    />
  );
};
