import { Add, Check } from '@mui/icons-material';
import { Fab, Tooltip } from '@mui/material';
import {
  HomeAppBar,
  InfiniteTable,
  SearchBar,
} from '@prismamedia/one-components';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { GetCategories } from '../../__generated__/queries-recipe';
import { useGetCategories } from '../../apollo/queries/categories.recipe.graphql';
import { AppDrawerMenu } from '../../components/AppDrawerMenu';
import { BrandMenu } from '../../components/BrandMenu';
import { paths, replaceParams } from '../../routing';
import { auth } from '../../utils/auth';
import { formatDate } from '../../utils/formatDate';
import {
  useGlobalBrandKey,
  useGlobalCategorySearchParams,
} from '../../utils/globalState';
import { useStyles } from './styles';
import { PAGINATION, useCategoriesVariables } from './utils';

export const CategoryListPage: FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const [brandKey] = useGlobalBrandKey(true);
  const [searchParams, setSearchParams] = useGlobalCategorySearchParams();
  const variables = useCategoriesVariables();

  const { data, loading, error, fetchMore } = useGetCategories(
    variables,
    !brandKey,
  );

  return (
    <>
      <HomeAppBar
        appDrawerMenu={<AppDrawerMenu />}
        brandMenu={<BrandMenu />}
        currentUser={auth.user}
        disconnectUser={auth.logout}
        searchBar={
          <SearchBar
            placeholder="Rechercher une catégorie"
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
        }
      />

      <div className={classes.listWrapper}>
        <InfiniteTable
          list={data?.categories}
          loading={loading || !brandKey}
          error={error}
          threshold={PAGINATION / 2}
          fetchMore={({ startIndex }) =>
            fetchMore({ variables: { skip: startIndex } })
          }
          columns={[
            {
              label: 'Titre de la catégorie',
              key: 'title',
              width: 200,
              flexGrow: 1,
            },
            {
              label: 'Nbr de recettes',
              key: 'recipeCategoryCount',
              width: 140,
            },
            {
              label: 'Date de création',
              key: 'createdAt',
              cellDataGetter: ({
                rowData,
              }: {
                rowData: GetCategories.Categories;
              }) => formatDate(rowData.createdAt),
              width: 200,
            },
            {
              label: 'Catégorie parent',
              key: 'parent',
              cellDataGetter: ({
                rowData,
              }: {
                rowData: GetCategories.Categories;
              }) => rowData.parent?.title,
              width: 200,
              flexGrow: 1,
            },
            {
              label: 'Publié',
              key: 'status',
              cellDataGetter: ({
                rowData,
              }: {
                rowData: GetCategories.Categories;
              }) => (rowData.status === 'published' ? <Check /> : null),
              width: 80,
            },
          ]}
          onRowClick={(e, category) => {
            e.preventDefault();
            brandKey &&
              history.push(
                replaceParams(paths.CATEGORY_EDIT, {
                  id: category.id,
                  brandKey,
                }),
              );
          }}
        />
      </div>
      <Tooltip title={`Créer une catégorie ${brandKey}`}>
        <Fab
          className={classes.newButton}
          color="primary"
          href={brandKey && replaceParams(paths.CATEGORY_NEW, { brandKey })}
          onClick={(e) => {
            e.preventDefault();
            brandKey &&
              history.push(replaceParams(paths.CATEGORY_NEW, { brandKey }));
          }}
        >
          <Add />
        </Fab>
      </Tooltip>
    </>
  );
};
