import { gql } from '@apollo/client';
import {
  GetFocusPointQuery,
  GetFocusPointQueryVariables,
} from '../../__generated__/queries-image';
import { useQueryPromise } from '../../utils/useQueryPromise';

export const GET_FOCUS_POINT = gql`
  query GetFocusPoint(
    $imageUrl: String!
    $imageWidth: Int!
    $imageHeight: Int!
  ) {
    getFocusPoint(
      imageUrl: $imageUrl
      imageWidth: $imageWidth
      imageHeight: $imageHeight
    ) {
      relativeX
      relativeY
      x
      y
    }
  }
`;

export const useFocusPointGetter = () =>
  useQueryPromise<GetFocusPointQuery, GetFocusPointQueryVariables>(
    GET_FOCUS_POINT,
  );
