import { MEDIA_TYPES_LABEL } from '@prismamedia/one-components';
import {
  GetRecipesQuery,
  GetRecipesQueryVariables,
} from '../../__generated__/queries-recipe';
import {
  ArticleFormat,
  BrandKey,
  SearchArticlesQuery,
  SearchArticlesQueryVariables,
} from '../../__generated__/queries-web';
import { client } from '../../apollo';
import { SEARCH_ARTICLES } from '../../apollo/queries/articles.web.graphql';
import { GET_RECIPES } from '../../apollo/queries/recipes.recipe.graphql';

const PAGE_RESULT = 10;
const DEFAULT_PAGE = 1;
const DEFAULT_PAGE_RESULT = 25;

type SearchResult<T> = T;

const extractPath = (str: string) => {
  try {
    return new URL(str).pathname;
  } catch (e) {
    return undefined;
  }
};

const searchArticles = async (
  search: string | undefined,
  articleFormat: ArticleFormat,
  page: number,
  pageResult?: number,
  brandKey?: BrandKey,
) => {
  // can't use hook, used outside components
  const { data } = await client.query<
    SearchArticlesQuery,
    SearchArticlesQueryVariables
  >({
    query: SEARCH_ARTICLES,
    variables: {
      skip: (page - 1) * PAGE_RESULT,
      first: pageResult ? pageResult : PAGE_RESULT,
      where: {
        title_contains: search,
        format: articleFormat,
        brandKey,
        status_in:
          articleFormat === 'Video'
            ? ['Private', 'Published', 'Ready']
            : ['Published'],
      },
      orderBy:
        articleFormat === 'Video' ? ['createdAt_DESC'] : ['publishedAt_DESC'],
    },
  });
  if (data) {
    return data.searchArticles;
  }
  return [];
};

const searchRecipes = async (
  search: string | undefined,
  brandKey: BrandKey,
  page: number,
) => {
  if (page > 1) return [];

  const searchUrl = extractPath(search || '');

  const { data } = await client.query<
    GetRecipesQuery,
    GetRecipesQueryVariables
  >({
    query: GET_RECIPES,
    variables: {
      first: 100,
      where: {
        title_contains: !searchUrl ? search : undefined,
        recipeUrls_some: searchUrl ? { url: { path: searchUrl } } : undefined,
        status: 'Published',
        brandKey,
      },
      orderBy: ['publishedAt_DESC'],
    },
  });

  if (data) {
    return data.recipes;
  }
  return [];
};

export const searchByPluginType = async (
  search: string | undefined,
  pluginType: string,
  brandKey?: BrandKey,
  page?: number,
  pageResult?: number,
): Promise<SearchResult<any>[]> => {
  const searchPage = page ? page : DEFAULT_PAGE;
  const searchResult = pageResult ? pageResult : DEFAULT_PAGE_RESULT;

  switch (pluginType) {
    case MEDIA_TYPES_LABEL.ARTICLES:
      return searchArticles(search, 'Rich', searchPage, searchResult, brandKey);
    case MEDIA_TYPES_LABEL.RECIPES:
      return searchRecipes(search, brandKey || 'FAC', searchPage);
    case MEDIA_TYPES_LABEL.VIDEOS:
      return searchArticles(search, 'Video', searchPage, searchResult);
    case MEDIA_TYPES_LABEL.SLIDESHOWS:
      return searchArticles(search, 'Slideshow', searchPage, searchResult);
    default:
      return Promise.reject(`Unknown plugin type: ${pluginType}`);
  }
};
