import React, { FC, useState, useEffect } from 'react';
import {
  AutocompleteItem,
  AutocompleteSelect,
  isDefined,
} from '@prismamedia/one-components';
import { GetCategories, Recipe } from '../../__generated__/queries-recipe';
import { useFetchCategories } from '../../utils/useFetchCategories';

interface CategorySelectProps {
  label: string;
  category?: { id: string; title: string } | null;
  onSelect?: (category: GetCategories.Categories | null) => void;
  className?: string;
  brandKey: string | null;
  displayCount?: boolean;
  selectedCategories?: Recipe.RecipeCategories[];
  minLevel?: number;
  disabled?: boolean;
  required?: boolean;
}

export const CategorySelect: FC<CategorySelectProps> = ({
  label: inputLabel,
  category,
  onSelect,
  className,
  brandKey,
  displayCount,
  selectedCategories,
  minLevel,
  disabled,
  required,
}) => {
  const [value, setValue] = useState<AutocompleteItem | undefined>(undefined);

  const secondaryCategoryIds = selectedCategories
    ?.filter(({ position }) => position > 1)
    .map(({ category: secCat }) => secCat)
    .filter(isDefined)
    .map((secCat) => secCat.id);

  const fetchCategories = useFetchCategories(
    brandKey,
    secondaryCategoryIds || [],
    displayCount,
    minLevel,
  );

  useEffect(() => {
    setValue(category ? { id: category.id, label: category.title } : undefined);
  }, [category]);

  return (
    <AutocompleteSelect
      label={inputLabel}
      value={value}
      className={className}
      fetchList={fetchCategories}
      onChange={(item) => {
        onSelect && onSelect(item?.additionnals?.category || null);
      }}
      disabled={disabled}
      TextFieldProps={{ required }}
    />
  );
};
