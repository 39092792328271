import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  wordCount: {
    display: 'flex',
    justifyContent: 'flex-end',
    color: 'gray',
    fontSize: '0.75rem',
    marginTop: '0.4rem',
  },
  centered: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    alignItems: 'center',
  },
  errorIcon: {
    marginRight: theme.spacing(2),
  },
  field: {
    marginTop: theme.spacing(4),
  },
  label: {
    color: 'gray',
  },
  divider: {
    marginBottom: '0.4rem',
  },
}));
