import { useMutation, gql } from '@apollo/client';
import {
  useNotification,
  NotificationTypeEnum,
} from '@prismamedia/one-components';
import { GET_CATEGORIES } from '../queries/categories.recipe.graphql';
import {
  CategoryUpdateInput,
  CategoryCreateInput,
  UpdateCategoryMutation,
  UpdateCategoryMutationVariables,
  CreateCategoryMutation,
  CreateCategoryMutationVariables,
} from '../../__generated__/queries-recipe';
import { CATEGORY_FRAGMENT } from '../fragments/category.recipe.graphql';
import { useCategoriesVariables } from '../../pages/CategoryListPage/utils';
import { useCallback } from 'react';

const UPDATE_CATEGORY = gql`
  mutation UpdateCategory(
    $where: CategoryWhereUniqueInput!
    $data: CategoryUpdateInput!
  ) {
    updateCategory(where: $where, data: $data) {
      ...Category
    }
  }
  ${CATEGORY_FRAGMENT}
`;

export const useUpdateCategory = () => {
  const { pushNotification } = useNotification();
  const [updateCategory] = useMutation<
    UpdateCategoryMutation,
    UpdateCategoryMutationVariables
  >(UPDATE_CATEGORY, {
    onError: (e) => {
      pushNotification({
        message: e.message,
        type: NotificationTypeEnum.error,
      });
    },
  });

  return useCallback(
    (id: string, data: CategoryUpdateInput) =>
      updateCategory({
        variables: {
          where: { id },
          data,
        },
      }),
    [updateCategory],
  );
};

const CREATE_CATEGORY = gql`
  mutation CreateCategory($data: CategoryCreateInput!) {
    createCategory(data: $data) {
      ...Category
    }
  }
  ${CATEGORY_FRAGMENT}
`;

export const useCreateCategory = () => {
  const { pushNotification } = useNotification();
  const variables = useCategoriesVariables();
  const [createCategory] = useMutation<
    CreateCategoryMutation,
    CreateCategoryMutationVariables
  >(CREATE_CATEGORY, {
    refetchQueries: [{ query: GET_CATEGORIES, variables }],
    onError: (e) => {
      pushNotification({
        message: e.message,
        type: NotificationTypeEnum.error,
      });
    },
  });

  return useCallback(
    (data: CategoryCreateInput) =>
      createCategory({
        variables: {
          data,
        },
      }),
    [createCategory],
  );
};
