import { useQuery, gql } from '@apollo/client';
import { useQueryPromise } from '../../utils/useQueryPromise';
import {
  GetCategoriesQuery,
  GetCategoriesQueryVariables,
  GetCategoryQuery,
  GetCategoryQueryVariables,
} from '../../__generated__/queries-recipe';
import { CATEGORY_FRAGMENT } from '../fragments/category.recipe.graphql';

export const GET_CATEGORIES = gql`
  query GetCategories(
    $first: Int!
    $skip: Int
    $where: CategoryWhereInput!
    $orderBy: [CategoryOrderByInput!]
    $countWhere: RecipeCategoryWhereInput
    $fetchCount: Boolean!
  ) {
    categories(first: $first, skip: $skip, where: $where, orderBy: $orderBy) {
      ...Category
      recipeCategoryCount(where: $countWhere) @include(if: $fetchCount)
    }
  }
  ${CATEGORY_FRAGMENT}
`;

export const useGetCategories = (
  variables: GetCategoriesQueryVariables,
  skip?: boolean,
) =>
  useQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GET_CATEGORIES, {
    variables,
    skip,
    notifyOnNetworkStatusChange: true,
  });

export const useGetCategoriesPromise = () =>
  useQueryPromise<GetCategoriesQuery, GetCategoriesQueryVariables>(
    GET_CATEGORIES,
  );

const GET_CATEGORY = gql`
  query GetCategory($where: CategoryWhereUniqueInput!) {
    category(where: $where) {
      ...Category
    }
  }
  ${CATEGORY_FRAGMENT}
`;

export const useGetCategory = (id?: string, skip?: boolean) =>
  useQuery<GetCategoryQuery, GetCategoryQueryVariables>(GET_CATEGORY, {
    variables: { where: { id } },
    skip: !id || skip,
  });
