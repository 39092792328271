import React, { FC, useState } from 'react';
import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
  CardActions,
  IconButton,
  CircularProgress,
  MenuItem,
} from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { useStyles } from './styles';
import { getStatus } from '../../../utils/recipeStatus';
import { paths, replaceParams } from '../../../routing';
import { ActionsMenu } from '../../../components/ActionsMenu';
import { GetRecipes } from '../../../__generated__/queries-recipe';
import clsx from 'clsx';
import { formatDate } from '../../../utils/formatDate';
import {
  getImageFromMedias,
  getThumbnail,
  parseMedias,
  ImageLoader,
  LockButton,
  useMenu,
  MediaIcons,
} from '@prismamedia/one-components';
import { auth } from '../../../utils/auth';
import { useUpdateRecipeLockerId } from '../../../apollo/mutations/recipes.recipe.graphql';
import { useHistory } from 'react-router-dom';

interface RecipeCardProps {
  recipe: GetRecipes.Recipes;
}

export const RecipeCard: FC<RecipeCardProps> = ({ recipe }) => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { openMenu, closeMenu } = useMenu();
  const updateLockerId = useUpdateRecipeLockerId();

  const Description = () => {
    switch (recipe.status) {
      case 'Published': {
        return (
          <>
            <span className={classes.status}>Publié </span>
            {recipe.authorName && (
              <>
                par
                <b> {recipe.authorName} </b>
              </>
            )}
            <span>le </span>
            {formatDate(recipe.publishedAt)}
          </>
        );
      }
      case 'Scheduled': {
        return (
          <>
            <span>Publication programmée pour le </span>
            <b>{formatDate(recipe.publishedAt)}</b>
          </>
        );
      }
      case 'Draft': {
        return (
          <>
            <span>Brouillon créé </span>
            {recipe.authorName && (
              <>
                par
                <b> {recipe.authorName} </b>
              </>
            )}
            <span>le </span>
            {formatDate(recipe.createdAt)}
          </>
        );
      }
      default:
        return <span>{getStatus(recipe.status)?.textSingular}</span>;
    }
  };

  return (
    <Card className={classes.card}>
      <CardActionArea
        className={clsx(classes.actionArea, loading && classes.cardLoading)}
        href={replaceParams(paths.DETAILS, {
          brandKey: recipe.brandKey,
          id: recipe.id,
        })}
        onClick={(e) => {
          e.preventDefault();
          history.push(
            replaceParams(paths.DETAILS, {
              brandKey: recipe.brandKey,
              id: recipe.id,
            }),
          );
        }}
        disabled={loading}
      >
        <ImageLoader
          className={classes.media}
          src={getThumbnail(recipe.medias)}
          timeout={1000}
          fallbacks={[
            {
              src: getImageFromMedias(parseMedias(recipe.medias))
                .mediaForDisplay,
            },
          ]}
        />
        <CardContent>
          <Typography className={classes.title} variant="h5" component="h2">
            {recipe.title}
          </Typography>
          <Typography variant="body1" color="textSecondary" component="p">
            <Description />
          </Typography>
        </CardContent>
        <MediaIcons
          className={classes.mediaIcons}
          mediasAsJson={recipe.medias}
        />
      </CardActionArea>
      {!!getStatus(recipe.status)?.actions.length && (
        <CardActions className={classes.cardActions}>
          <IconButton
            disabled={loading}
            onClick={(e) => {
              openMenu(
                e.currentTarget,
                <ActionsMenu recipe={recipe} setLoading={setLoading} />,
              );
            }}
            size="large"
          >
            <MoreVert />
          </IconButton>
          {recipe.lockedBy && (
            <LockButton
              className={classes.lockButton}
              lockedBy={recipe.lockedBy as { name: string; avatarUrl: string }}
              disableLockForV0
              onClick={(e) => {
                if (recipe.lockedBy?.id === auth.user?.id) {
                  openMenu(
                    e.currentTarget,
                    <MenuItem
                      onClick={() => {
                        updateLockerId(recipe.id, null);
                        closeMenu();
                      }}
                    >
                      Terminer l'édition
                    </MenuItem>,
                  );
                }
              }}
            />
          )}
        </CardActions>
      )}
      {loading && (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      )}
    </Card>
  );
};
