import {
  Divider,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import {
  EditAppBar,
  MaterialDraft,
  NonEditableField,
  OneMedia,
  PageWithDrawer,
  SimpleCheckBox,
  draftActions,
  getRequiredErrorMessage,
  objectsAreDifferents,
} from '@prismamedia/one-components';
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  CreateCategoryMutation,
  GetCategory,
} from '../../__generated__/queries-recipe';
import { BrandKey } from '../../__generated__/queries-web';
import {
  useCreateCategory,
  useUpdateCategory,
} from '../../apollo/mutations/categories.recipe.graphql';
import { useGetCategory } from '../../apollo/queries/categories.recipe.graphql';
import { CategorySelect } from '../../components/CategorySelect';
import { MediaInput } from '../../components/MediaInput';
import { MultimediaPlugin } from '../../components/MediaInput/MultimediaPlugin';
import { getEmptyCategory } from '../../const/category';
import { paths, replaceParams } from '../../routing';
import { formatDate } from '../../utils/formatDate';
import { useStyles } from './styles';
import {
  getCategoryCreateInput,
  getCategoryUpdateInput,
  saveMandatoryFields,
} from './utils';

export const CategoryEditPage: FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const newCategory = location.pathname.endsWith('/new');
  const [, newBrandKey] = location.pathname.split('/');
  const [category, setCategory] = useState<GetCategory.Category | undefined>(
    undefined,
  );
  const { id: categoryId } = useParams<{ id: string }>();
  const { data, loading, error } = useGetCategory(categoryId, newCategory);
  const [saveLoading, setSaveLoading] = useState(false);
  const updateCategory = useUpdateCategory();
  const createCategory = useCreateCategory();
  const emptyCategory = useMemo(() => getEmptyCategory(newBrandKey), [
    newBrandKey,
  ]);
  const categoryDiff = objectsAreDifferents(
    category,
    newCategory ? emptyCategory : data?.category,
  );
  const requiredFields = getRequiredErrorMessage<GetCategory.Category>(
    category,
    saveMandatoryFields,
  );

  useEffect(() => {
    setCategory(newCategory ? emptyCategory : data?.category || undefined);
  }, [newCategory, data, emptyCategory, setCategory]);

  const onSave = async () => {
    if (category) {
      setSaveLoading(true);
      const res = newCategory
        ? await createCategory(getCategoryCreateInput(category))
        : await updateCategory(category.id, getCategoryUpdateInput(category));
      setSaveLoading(false);
      if (res && newCategory) {
        history.replace(
          replaceParams(paths.CATEGORY_EDIT, {
            brandKey: newBrandKey,
            id: (res.data as CreateCategoryMutation).createCategory.id,
          }),
        );
      }
    }
  };

  const getMediasFromString = useCallback(
    (mediasAsString: string | null | undefined) => {
      if (!mediasAsString) return [];
      try {
        return JSON.parse(mediasAsString) as OneMedia[];
      } catch (e) {
        return [];
      }
    },
    [],
  );

  const medias = useMemo(() => {
    return getMediasFromString(category?.medias);
  }, [category?.medias, getMediasFromString]);

  const onMediaChange: Dispatch<SetStateAction<OneMedia[]>> = useCallback(
    (updater) => {
      setCategory((prev) => {
        const newMedias =
          typeof updater === 'function'
            ? updater(getMediasFromString(prev?.medias))
            : updater;
        return prev ? { ...prev, medias: JSON.stringify(newMedias) } : prev;
      });
    },
    [getMediasFromString],
  );

  return (
    <>
      <EditAppBar
        title={
          newCategory
            ? category?.title || 'Nouvelle catégorie'
            : data?.category?.title
        }
        subTitle={
          !newCategory
            ? `Créé le ${formatDate(
                category?.createdAt,
              )} - Dernière modification le ${formatDate(category?.updatedAt)}`
            : undefined
        }
        onNavigateBack={() => history.push(paths.CATEGORY_LIST)}
        save={{
          disabled: !categoryDiff && !newCategory,
          loading: saveLoading,
          onClick: onSave,
          requiredFields,
        }}
      />
      <PageWithDrawer
        loading={loading}
        error={
          error ||
          (!category && !data?.category
            ? Error("Cette catégorie n'existe pas")
            : undefined)
        }
        rightDrawer={
          category && (
            <>
              <NonEditableField
                className={classes.field}
                label="Marque"
                value={category.brandKey}
              />
              <NonEditableField
                className={classes.field}
                label="Slug"
                value={category.slug}
              />

              {!newCategory && (
                <>
                  <Divider className={classes.divider} />
                  <Typography variant="caption" className={classes.grey}>
                    Sous-catégories
                  </Typography>
                  <List>
                    {!category.children.length && (
                      <Typography
                        className={classes.emptyChildren}
                        variant="body2"
                      >
                        Aucune
                      </Typography>
                    )}
                    {category.children.map(({ id, title }) => (
                      <ListItem
                        key={id}
                        button
                        href={replaceParams(paths.CATEGORY_EDIT, {
                          id,
                          brandKey: category.brandKey,
                        })}
                        onClick={(e) => {
                          e.preventDefault();
                          history.push(
                            replaceParams(paths.CATEGORY_EDIT, {
                              id,
                              brandKey: category.brandKey,
                            }),
                          );
                        }}
                      >
                        <ListItemText primary={title} />
                      </ListItem>
                    ))}
                  </List>
                </>
              )}
            </>
          )
        }
      >
        {category && (
          <>
            <TextField
              variant="standard"
              label="Titre"
              value={category.title}
              className={classes.field}
              onChange={({ target: { value } }) => {
                setCategory((prev) =>
                  prev ? { ...prev, title: value } : prev,
                );
              }}
            />
            <TextField
              variant="standard"
              label="Sous-titre"
              value={category.subtitle || ''}
              className={classes.field}
              onChange={({ target: { value } }) => {
                setCategory((prev) =>
                  prev ? { ...prev, subtitle: value } : prev,
                );
              }}
            />

            <MediaInput
              sx={{ mt: 4 }}
              medias={medias}
              setMedias={onMediaChange}
              brandKey={category?.brandKey as BrandKey}
            />

            <CategorySelect
              disabled={!newCategory}
              label="Catégorie parent"
              brandKey={category.brandKey}
              className={classes.field}
              category={category.parent}
              onSelect={(parent) =>
                setCategory((prev) => (prev ? { ...prev, parent } : prev))
              }
              minLevel={1}
            />

            <MaterialDraft
              className={classes.field}
              label="Corps du texte"
              value={category.body}
              onChange={(body) => {
                setCategory((prev) => (prev ? { ...prev, body } : prev));
              }}
              wordCounter
              actions={[
                draftActions.BOLD,
                draftActions.ITALIC,
                draftActions.LINK,
              ]}
              plugins={[MultimediaPlugin]}
            />

            <SimpleCheckBox
              className={classes.field}
              label="Publié"
              checked={category.status === 'published'}
              onChange={(checked) => {
                setCategory((prev) =>
                  prev
                    ? { ...prev, status: checked ? 'published' : null }
                    : prev,
                );
              }}
            />
          </>
        )}
      </PageWithDrawer>
    </>
  );
};
