import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: theme.spacing(4),
  },
  emptyChildren: {
    color: theme.palette.grey[600],
  },
  grey: {
    color: theme.palette.grey[600],
  },
  divider: {
    margin: theme.spacing(4, -3),
  },
}));
