import makeStyles from '@mui/styles/makeStyles';

const appBarHeight = 64;

export const useStyles = makeStyles((theme) => ({
  listWrapper: {
    height: `calc(100vh - ${appBarHeight}px)`,
    marginTop: appBarHeight,
  },
  newButton: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
}));
