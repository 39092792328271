import type { FieldValidator } from '@prismamedia/one-components';
import { isImage, OneMedia } from '@prismamedia/one-components';
import type { GetRecipe } from '../__generated__/queries-recipe';

export const saveMandatoryFields: FieldValidator<GetRecipe.Recipe>[] = [
  {
    label: 'Titre',
    validate: ({ title }) => !!title,
  },
];

export const publishMandatoryFields: FieldValidator<GetRecipe.Recipe>[] = [
  ...saveMandatoryFields,
  {
    label: 'Image',
    validate: ({ medias }) => {
      let parsedMedias: OneMedia[] = [];
      try {
        parsedMedias = JSON.parse(medias || '');
      } catch (e) {}
      return !!parsedMedias.length;
    },
  },
  {
    label: "Crédit de l'image",
    validate: ({ medias }) => {
      let parsedMedias: OneMedia[] = [];
      try {
        parsedMedias = JSON.parse(medias || '');
      } catch (e) {}
      if (!parsedMedias.length) return true;
      return parsedMedias.every(
        (media) =>
          !isImage(media) || !!media.credit || !!media.iframely?.meta?.credit,
      );
    },
  },
  {
    label: "Source de l'image",
    validate: ({ medias }) => {
      let parsedMedias: OneMedia[] = [];
      try {
        parsedMedias = JSON.parse(medias || '');
      } catch (e) {}
      if (!parsedMedias.length) return true;
      return parsedMedias.every((media) => !isImage(media) || !!media.source);
    },
  },
  {
    label: 'Nombre de personnes',
    validate: (recipe) => !!recipe.yield,
  },
  {
    label: 'Ingrédients',
    validate: ({ rawIngredients }) => !!rawIngredients,
  },
  {
    label: 'Étapes de la recette',
    validate: ({ instructions }) => !!instructions,
  },
  {
    label: 'Catégorie principale',
    validate: ({ recipeCategories }) =>
      !!recipeCategories.find((cat) => cat.position === 1 && cat.category),
  },
  {
    label: 'Tag',
    validate: ({ recipeTags }) => !!recipeTags.find(({ tag }) => !!tag),
  },
];
