import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(3),
    maxWidth: 300,
  },
  body: {
    marginTop: theme.spacing(2),
  },
}));
