import { GetRecipe } from '../__generated__/queries-recipe';

export const getFrontUrl = (recipe?: GetRecipe.Recipe) => {
  if (!recipe) {
    return undefined;
  }

  const basePath = config.FRONT_URLS[recipe.brandKey as 'CAC' | 'FAC'];
  const path = recipe.recipeUrls[0]?.url?.path;

  return basePath && path && basePath + path;
};
