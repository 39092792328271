import { EditorState, Modifier } from 'draft-js';
import { insertNewUnstyledBlock } from 'draftjs-utils';
import { standardizeArticleUrl } from '../utils';

interface DisplayLinkInput {
  id: string;
  title: string | null;
  url: string;
}

export const buildDraftLink = (
  editorState: EditorState,
  { id, title, url }: DisplayLinkInput,
) => {
  const entityID = editorState
    .getCurrentContent()
    .createEntity('LINK', 'MUTABLE', {
      children: title,
      id,
      url: standardizeArticleUrl(url),
    });

  const linkContentBlock = Modifier.insertText(
    editorState.getCurrentContent(),
    editorState.getSelection(),
    `⋙  ${title}`,
    undefined,
    entityID.getLastCreatedEntityKey(),
  );

  let newEditorState = EditorState.push(
    editorState,
    linkContentBlock,
    'insert-fragment',
  );

  // Insert new empty block below
  newEditorState = insertNewUnstyledBlock(newEditorState);

  return newEditorState;
};
