import { InputProps, TextField, useMediaQuery } from '@mui/material';
import {
  ImagePlugin,
  MaterialDraft,
  MediaPlugin,
  NotificationTypeEnum,
  OneMedia,
  PageWithDrawer,
  getRequiredErrorMessage,
  objectsAreDifferents,
  useConcurrentialAccess,
  useNotification,
  useUnsavedWork,
} from '@prismamedia/one-components';
import {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { GetRecipe } from '../../__generated__/queries-recipe';
import { BrandKey } from '../../__generated__/queries-web';
import {
  useCreateRecipe,
  useUpdateRecipe,
  useUpdateRecipeLockerId,
} from '../../apollo/mutations/recipes.recipe.graphql';
import { useGetImageSourceCategories } from '../../apollo/queries/imageSourceCategories.web.graphql';
import { useGetRecipe } from '../../apollo/queries/recipes.recipe.graphql';
import { MediaInput } from '../../components/MediaInput';
import { MultimediaPlugin } from '../../components/MediaInput/MultimediaPlugin';
import {
  embedImage,
  uploadAndEmbedImage,
} from '../../components/MediaInput/utils';
import { TextFieldWordCount } from '../../components/TextFieldWordCount';
import { NEW_RECIPE_ID, getEmptyRecipe } from '../../const/recipe';
import { paths, replaceParams } from '../../routing';
import { theme } from '../../theme';
import { auth } from '../../utils/auth';
import { draftActions } from '../../utils/draftActions';
import { getStatus } from '../../utils/recipeStatus';
import { DetailsAppBar } from './DetailsAppBar';
import { InformationsDrawer } from './InformationsDrawer';
import { Ingredients } from './Ingredients';
import { useStyles } from './styles';
import {
  getRecipeCreateInput,
  getRecipeUpdateInput,
  getUnlockLink,
} from './utils';

export const RecipeDetailsPage: FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const newRecipe = location.pathname.endsWith('/new');
  const [, newBrandKey] = location.pathname.split('/');
  const [recipe, setRecipe] = useState<GetRecipe.Recipe | undefined>(undefined);
  const { id: recipeId } = useParams<{ id: string }>();
  const { data, loading, error } = useGetRecipe(recipeId, newRecipe);
  const [saveLoading, setSaveLoading] = useState(false);
  const updateRecipe = useUpdateRecipe();
  const createRecipe = useCreateRecipe();
  const updateLockerId = useUpdateRecipeLockerId();
  const { pushNotification } = useNotification();
  const emptyRecipe = useMemo(() => getEmptyRecipe(newBrandKey), [newBrandKey]);
  const recipeHasChanged = objectsAreDifferents(
    recipe,
    newRecipe ? emptyRecipe : data?.recipe,
  );
  const getImageSourceCategories = useGetImageSourceCategories();
  const requiredFields = getRequiredErrorMessage(
    recipe,
    getStatus(recipe?.status)?.mandatoryFields,
  );

  const { clearUnsavedWork } = useUnsavedWork(
    newRecipe ? `${NEW_RECIPE_ID}_${newBrandKey}` : recipeId,
    recipe,
    recipeHasChanged,
    setRecipe,
  );

  useConcurrentialAccess({
    lockStatus: newRecipe ? undefined : recipe,
    unlockTimeout: recipeHasChanged ? 1800000 : 300000,
    currentUser: auth.user,
    onLock: (firstLock) =>
      updateLockerId(recipeId, auth?.user?.id || null, !firstLock),
    unlockLink: getUnlockLink(recipeId),
    clearUnsavedWork,
    onNavigateBack: () => history.push(paths.HOME),
    disableLockForV0: true,
  });

  useEffect(() => {
    setRecipe(newRecipe ? emptyRecipe : data?.recipe || undefined);

    if (data?.recipe?.title) {
      // eslint-disable-next-line immutable/no-mutation
      document.title = data.recipe.title;
    }
  }, [newRecipe, data, emptyRecipe, setRecipe]);

  const setParsedIngredients = useCallback(
    (parsedIngredients) => {
      setRecipe((prev) => (prev ? { ...prev, parsedIngredients } : prev));
    },
    [setRecipe],
  );

  const onSave = async () => {
    if (recipe) {
      setSaveLoading(true);
      if (newRecipe) {
        const res = await createRecipe(getRecipeCreateInput(recipe));
        if (res.data) {
          history.replace(
            replaceParams(paths.DETAILS, {
              brandKey: newBrandKey,
              id: res.data?.createRecipe.id,
            }),
          );
        }
      } else {
        const res = await updateRecipe(recipe.id, getRecipeUpdateInput(recipe));
        pushNotification({
          message: `${res.data?.updateRecipe?.title} a été sauvegardé`,
          type: NotificationTypeEnum.success,
        });
      }
      clearUnsavedWork();
      setSaveLoading(false);
    }
  };

  const getMediasFromString = useCallback(
    (mediasAsString: string | null | undefined) => {
      if (!mediasAsString) return [];
      try {
        return JSON.parse(mediasAsString) as OneMedia[];
      } catch (e) {
        return [];
      }
    },
    [],
  );

  const medias = useMemo(() => {
    return getMediasFromString(recipe?.medias);
  }, [recipe?.medias, getMediasFromString]);

  const onMediaChange: Dispatch<SetStateAction<OneMedia[]>> = useCallback(
    (updater) => {
      setRecipe((prev) => {
        const newMedias =
          typeof updater === 'function'
            ? updater(getMediasFromString(prev?.medias))
            : updater;
        return prev ? { ...prev, medias: JSON.stringify(newMedias) } : prev;
      });
    },
    [getMediasFromString],
  );

  return (
    <>
      <DetailsAppBar
        recipe={newRecipe ? recipe : data?.recipe || undefined}
        recipeHasChanged={recipeHasChanged || newRecipe}
        requiredMessage={requiredFields}
        saveRecipe={onSave}
        saveLoading={saveLoading}
        unlock={() =>
          !newRecipe && updateLockerId(recipeId, null).catch(() => {})
        }
      />

      <PageWithDrawer
        loading={loading}
        error={
          error ||
          (!recipe && !data?.recipe
            ? Error("Cette recette n'existe pas")
            : undefined)
        }
        rightDrawer={
          <InformationsDrawer recipe={recipe} setRecipe={setRecipe} />
        }
        paddingTop={!smallScreen || requiredFields ? 48 : 0}
      >
        {recipe && (
          <>
            <TextFieldWordCount
              label="Titre"
              variant="standard"
              value={recipe.title}
              onChange={(event: ChangeEvent<{ value: string }>) => {
                const { value } = event.target;
                setRecipe((prev) =>
                  prev ? { ...prev, title: value } : undefined,
                );
              }}
              inputProps={{
                'data-testid': 'detail-title',
              }}
              required
            />

            <MediaInput
              sx={{ mt: 4 }}
              medias={medias}
              setMedias={onMediaChange}
              brandKey={recipe?.brandKey as BrandKey}
              withMediaUploaders
            />

            <TextField
              label="Nombre de personnes"
              variant="standard"
              inputProps={{
                'data-testid': 'detail-person',
              }}
              required
              value={recipe.yield || ''}
              type="number"
              className={classes.field}
              onChange={(event: ChangeEvent<{ value: string }>) => {
                const { value } = event.target;
                if (value && Number(value) > 0) {
                  setRecipe((prev) =>
                    prev ? { ...prev, yield: Number(value) } : undefined,
                  );
                }
              }}
            />

            <Ingredients
              ingredients={recipe.rawIngredients}
              setIngredients={(rawIngredients) => {
                setRecipe((prev) =>
                  prev ? { ...prev, rawIngredients } : prev,
                );
              }}
              setParsedIngredients={setParsedIngredients}
              className={classes.field}
            />

            <MaterialDraft
              className={classes.field}
              label="Étapes de la recette"
              value={recipe.instructions}
              inputProps={
                {
                  'data-testid': 'detail-instructions',
                } as InputProps
              }
              required
              onChange={(instructions) => {
                setRecipe((prev) => (prev ? { ...prev, instructions } : prev));
              }}
              wordCounter
              actions={[
                draftActions.BOLD,
                draftActions.ITALIC,
                draftActions.STRIKETHROUGH,
                draftActions.LINK,
                draftActions.INTERNAL_LINK,
                draftActions.SEPARATOR,
                draftActions.TITLE,
                draftActions.HEADLINE,
                draftActions.LIST,
                draftActions.ORDERED_LIST,
                draftActions.BLOCKQUOTE,
              ]}
              plugins={[
                MediaPlugin({ handleEmbed: embedImage }),
                ImagePlugin({
                  handleEmbed: (file: File) =>
                    uploadAndEmbedImage(file, recipe.brandKey),
                  maxUploadSize: 10485760,
                  fetchSources: getImageSourceCategories,
                }),
                MultimediaPlugin,
              ]}
            />

            <MaterialDraft
              className={classes.field}
              label="Conseils"
              value={recipe.advice}
              onChange={(advice) => {
                setRecipe((prev) => (prev ? { ...prev, advice } : prev));
              }}
              wordCounter
              actions={[
                draftActions.BOLD,
                draftActions.ITALIC,
                draftActions.STRIKETHROUGH,
                draftActions.LINK,
                draftActions.INTERNAL_LINK,
                draftActions.SEPARATOR,
                draftActions.TITLE,
                draftActions.HEADLINE,
                draftActions.LIST,
                draftActions.ORDERED_LIST,
                draftActions.BLOCKQUOTE,
              ]}
              plugins={[MultimediaPlugin]}
            />
          </>
        )}
      </PageWithDrawer>
    </>
  );
};
