import makeStyles from '@mui/styles/makeStyles';
import { getSpacing } from '@prismamedia/one-components';

export const useStyles = makeStyles((theme) => {
  const appBarHeight = 64;
  const tabBarHeight = 48;
  const actionsMarginBottom = getSpacing(theme, 1);
  const actionsHeight = 34;
  const counterHeight = 30;
  const counterMarginTop = getSpacing(theme, 1);
  const counterMarginBottom = getSpacing(theme, 2);

  const totalHeight =
    appBarHeight + tabBarHeight + actionsMarginBottom + actionsHeight;
  const totalHeightWithCounter =
    totalHeight + counterHeight + counterMarginTop + counterMarginBottom;

  return {
    listWrapper: {
      marginTop: appBarHeight + tabBarHeight,
      paddingTop: theme.spacing(2),
      height: `calc(100vh - ${totalHeight}px)`,
    },
    listWrapperWithCounter: {
      height: `calc(100vh - ${totalHeightWithCounter}px)`,
    },
    list: {
      padding: `0 ${theme.spacing(2)}`,
    },
    searchCounterWrapper: {
      padding: theme.spacing(0, 2),
    },
    searchCounter: {
      margin: `${counterMarginTop}px auto ${counterMarginBottom}px`,
      maxWidth: 1200,
    },
    searchCounterText: {
      fontSize: '1.2rem',
    },
  };
});
