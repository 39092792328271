export const difficulties = [
  { label: '-', value: '' },
  { label: 'Très facile', value: 'Très facile' },
  { label: 'Facile', value: 'Facile' },
  { label: 'Moyen', value: 'Moyen' },
  { label: 'Difficile', value: 'Difficile' },
];

export const estimatedCosts = [
  { label: '-', value: '' },
  { label: 'Bon marché', value: 'Bon marché' },
  { label: 'Moyen', value: 'Moyen' },
  { label: 'Assez cher', value: 'Assez cher' },
  { label: 'Cher', value: 'Cher' },
];

export const calorieLevels = [
  { label: '-', value: '' },
  { label: 'Faible', value: 'Faible' },
  { label: 'Moyen', value: 'Moyen' },
  { label: 'Elevé', value: 'Elevé' },
  { label: 'Très élevé', value: 'Très élevé' },
];

export const timeValues = [
  { label: '-', value: '' },
  { label: 'Moins de 15 minutes', value: '900' },
  { label: 'Moins de 30 minutes', value: '1800' },
  { label: 'Moins de 45 minutes', value: '2700' },
];
