import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  wordCount: {
    alignSelf: 'flex-end',
    marginTop: theme.spacing(1),
    color: theme.palette.grey[600],
    transition: 'opacity 0.3s',
  },
  wordCountHidden: {
    opacity: 0,
  },
}));
