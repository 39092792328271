import { gql, useQuery } from '@apollo/client';
import {
  GetRecipeQuery,
  GetRecipeQueryVariables,
  GetRecipesQuery,
  GetRecipesQueryVariables,
} from '../../__generated__/queries-recipe';
import { useQueryPromise } from '../../utils/useQueryPromise';
import { RECIPE_FRAGMENT } from '../fragments/recipe.recipe.graphql';

export const GET_RECIPES = gql`
  query GetRecipes(
    $first: Int!
    $skip: Int
    $orderBy: [RecipeOrderByInput!]
    $where: RecipeWhereInput!
  ) {
    recipeCount(where: $where)
    recipes(first: $first, skip: $skip, orderBy: $orderBy, where: $where) {
      ...Recipe
    }
  }
  ${RECIPE_FRAGMENT}
`;

export const useGetRecipes = (
  variables: GetRecipesQueryVariables,
  skip?: boolean,
) =>
  useQuery<GetRecipesQuery, GetRecipesQueryVariables>(GET_RECIPES, {
    variables,
    skip,
    notifyOnNetworkStatusChange: true,
  });

export const useGetRecipesPromise = () =>
  useQueryPromise<GetRecipesQuery, GetRecipesQueryVariables>(GET_RECIPES);

const GET_RECIPE = gql`
  query GetRecipe($where: RecipeWhereUniqueInput!) {
    recipe(where: $where) {
      ...Recipe
    }
  }
  ${RECIPE_FRAGMENT}
`;

export const useGetRecipe = (id: string, skip?: boolean) =>
  useQuery<GetRecipeQuery, GetRecipeQueryVariables>(GET_RECIPE, {
    variables: { where: { id } },
    skip: !id || skip,
  });
